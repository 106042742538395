import React from 'react';
import InputMask from 'react-input-mask';

function ShopkeeperForm(props) {

    const formData = props.formShopkepeerData;
    const handleChange = props.handleChangeShopkeeper;

    return (
        <div className='shopkeeper-form'>
            <div className='form-title'>Dados do Lojista</div>
            <div className='form-subtitle'>Dados Pessoais</div>
            <label className='form-label mb2'>
                <div className='label-text'>Nome Completo</div>
                <input className="form-control" type="text" name="name" placeholder='Digite seu nome completo' value={formData.name} onChange={handleChange} required />
            </label>
            <label className='form-label mb1'>
                <div className='label-text'>CPF</div>
                <InputMask
                    className="form-control"
                    placeholder='Digite seu CPF'
                    type="text"
                    name="cpf"
                    mask="999.999.999-99" // Máscara para CPF
                    value={formData.cpf}
                    onChange={handleChange}
                    required
                />
            </label>
            <label className='form-label mb1'>
                <div className='label-text'>Telefone</div>
                <InputMask
                    className="form-control"
                    placeholder='Digite seu telefone'
                    type="text"
                    name="cellNumber"
                    mask="(99) 99999-9999" // Máscara para Telefone
                    value={formData.cellNumber}
                    onChange={handleChange}
                    required
                />
            </label>
            <div className='form-subtitle'>Endereço</div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>CEP</div>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CEP'
                        type="text"
                        name="cep"
                        mask="99999-999" // Máscara para CEP
                        value={formData.cep}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label className='form-label mb1'>
                    <div className='label-text'>Rua</div>
                    <input className="form-control" placeholder='Digite sua rua' type="text" name="street" value={formData.street} onChange={handleChange} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Nº</div>
                    <input className="form-control" type="number" placeholder='Digite seu número' name="address_number" value={formData.address_number} onChange={handleChange} required />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Bairro</div>
                    <input className="form-control" type="text" placeholder='Digite seu bairro' name="neighborhood" value={formData.neighborhood} onChange={handleChange} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Complemento</div>
                    <input className="form-control" type="text" placeholder='Digite seu complemento' name="complement" value={formData.complement} onChange={handleChange} />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Cidade</div>
                    <input className="form-control" type="text" placeholder='Digite sua cidade' name="city" value={formData.city} onChange={handleChange} required />
                </label>
            </div>

            <div className='form-subtitle'>Dados de acesso</div>
            <label className='form-label mb1'>
                <div className='label-text'>E-mail</div>
                <input className="form-control" type="email" placeholder='Digite seu e-mail' name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label className='form-label mb1'>
                <div className='label-text'>Senha</div>
                <input className="form-control" type="password" placeholder='Digite sua senha' name="password" value={formData.password} onChange={handleChange} required />
            </label>
        </div>
    );
}

export default ShopkeeperForm;
