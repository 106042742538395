export default class MascaraTelefone {
    constructor() {
        this.checkPrimeiraParte = new RegExp(/^\(/);
        this.checkSegundaParte = new RegExp(/^\((\d{2})$/);
        this.checkTerceiraParte = new RegExp(/^\((\d{2})\)(\d{5})$/);
    }

    adicionaMascaraTelefone(input) {
        if (!this.checkPrimeiraParte.test(input.value)) input.value = `(${input.value}`;

        if (this.checkSegundaParte.test(input.value)) input.value = `${input.value})`;

        if (this.checkTerceiraParte.test(input.value)) input.value = `${input.value}-`;
    }
}
