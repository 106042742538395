import { Component } from "react";
import "./style.scss";

class Button extends Component {
    static activeButton(button) {
        button.classList.add("button--active");
    }
    
    render() {
        return <button className={`button ${this.props.labelAnimation}`} type="button" data-calc-shipping={this.props.dataCalcShipping} onClick={this.props.onClick}>{this.props.content}</button>;
    }
}

export default Button;
