import HttpService from "./HttpService.js";

export default class WalletService {
    constructor() {
        this._http = new HttpService();
        this._baseUrl = `${process.env.REACT_APP_DELIVERIES_API}/wallet`;
    }

    async loadWallet(user_id) {
        const response = await this._http.get(this._baseUrl+`/${user_id}`);
        return response;
    }
}
