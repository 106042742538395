import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Navigate } from "react-router";
import animation from "./../../../assets/json/success.json";
import "./style.scss";

const SuccessPage = () => {
    const defaultOptions = {
        isPaused: false,
        direction: -1,
        isStopped: false,
        loop: false,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        document.title = "Entrega da Vizinhança";
        const timer = setTimeout(() => setRedirect(true), 3000);
        return () => clearTimeout(timer);
    }, []);

    if (redirect) return <Navigate to={"/conclusion"} />;

    return (
        <div className="animation">
            <div className="animation__container">
                <Lottie options={defaultOptions} width={"100%"} height={"100%"} />
            </div>
        </div>
    );
};

export default React.memo(SuccessPage);
