import React, { useEffect, useContext, createContext } from 'react';
import io from 'socket.io-client';

const SERVER_URL = process.env.REACT_APP_DELIVERIES_API;

const socket = io(SERVER_URL);

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  useEffect(() => {
    socket.connect();

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
