class Validation {
    static validAddressFields(elements) {
        let hasError = true;
        Array.from(elements).forEach((element) => {
            if (element.tagName === "INPUT" && !element.checkValidity()) hasError = false;
        });
        return hasError;
    }

    static showErrorMessage(elements) {
        Array.from(elements).forEach((element) => (element.tagName === "INPUT" ? element.reportValidity() : ""));
    }
}

export default Validation;
