import HttpService from "./HttpService.js";

export default class ShopkeeperService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/shopkeeper`;
    }

    async registerShopkeeper(data) {
        const response = await this._http.post(this._urlBase, data);
        return response;
    }

    async getPendingUsers(page) {
        const response = await this._http.get(this._urlBase+"/pendingusers/"+page);
        return response;
    }

    async aproveUser(id, email) {
        const response = await this._http.put(this._urlBase+`/${id}`, {"status": "aproved", "email": email});
        return response;
    }

    async disaproveUser(id, email) {
        const response = await this._http.put(this._urlBase+`/${id}`, {"status": "disapproved", "email": email});
        return response;
    }
}
