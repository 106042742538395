import HttpService from "./HttpService.js";

export default class OrderGroupingService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/ordergrouping`;
    }

    async createOrderGrouping(store, orders) {
        const response = await this._http.post(this._urlBase+`/`, {
            "store": store,
            "orders": orders
        });
        return response;
    }
}
