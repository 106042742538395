import { Component } from "react";
import "./style.scss";

class Field extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            invalid: false,
            errorActive: "",
        };
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    checkError() {
        this.setState({ invalid: "true" });
    }

    onInput(event) {
        if (this.props.onInput) this.props.onInput(event);
        this.setState(this.isInvalid(event.target));
        event.target.setCustomValidity("")
    }

    isInvalid(element) {
        if (element.value.length === 0) return { invalid: false, errorActive: "" };
        const result = { invalid: !element.checkValidity() };
        if (result.invalid) {
            result.errorActive = "field__input--error";
        } else {
            result.errorActive = "";
        }
        return result;
    }

    render() {
        return (
            <label className={`field ${this.props.labelAnimation}`} >
                <input
                    className={`field__input ${this.state.errorActive}`}
                    id={this.props.name}
                    maxLength={this.props.maxlength}
                    max={this.props.max}
                    min={this.props.min}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    onInput={this.onInput.bind(this)}
                    pattern={this.props.pattern}
                    onBlur={this.props.onBlur}
                    placeholder=""
                    required={this.props.required === "" ? false : true}
                    value={this.props.value}
                    minLength={this.props.minlength}
                    type={this.props.type}
                    inputMode={this.props.inputMode}
                    disabled={this.props.blockChange}
                    readOnly={this.props.readOnly}
                />
                <span className="field__icon"></span>
                <div className={`field__container-erro ${this.state.invalid ? "field__container-erro--show" : ""}`}>
                    <p className="field__mensagem-erro">{this.props.messageErro}</p>
                </div>
                <p className="field__label">{this.props.content}  {this.props.required === "" ? (<span className="field_optional">{"(opicional)"}</span>) : ("")}</p>
                
            </label>
        );
    }
}

export default Field;
