import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [stayConnected, setStayConnected] = useState(false);

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? JSON.parse(storedToken) : "";
  });

  const [stores, setStores] = useState(() => {
    const storedStores = localStorage.getItem('stores');
    return storedStores ? JSON.parse(storedStores) : [];
  });

  const [selectedStore, setSelectedStore] = useState(() => {
    const storedStore = localStorage.getItem('store');
    return storedStore ? JSON.parse(storedStore) : null;
  });

  const [hideSidebar, setHideSidebar] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? false : true;
  });

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user, stayConnected]);

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('store', JSON.stringify(selectedStore));
    }
  }, [stayConnected, selectedStore]);

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('stores', JSON.stringify(stores));
    }
  }, [stayConnected, stores]);

  const updateStayConnected = (isStayConnected) => {
    setStayConnected(isStayConnected);
  }

  const updateStore = (storeOption) => {
    localStorage.removeItem('store');
    setSelectedStore(stores[stores.findIndex(store => store._id === storeOption.value)]);
    localStorage.setItem('store', JSON.stringify(stores[stores.findIndex(store => store._id === storeOption.value)]));
  }

  const updateUser = (userData) => {
    setHideSidebar(false);
    setUser(userData.user);
    setToken(userData.token);
    setStores(userData.stores);
    setSelectedStore(userData.stores[0]);
    if (stayConnected) { // manter conectado?
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('store', JSON.stringify(selectedStore));
      localStorage.setItem('stores', JSON.stringify(stores));
    }
  };

  return (
    <UserContext.Provider value={{ user, token, stores, selectedStore, hideSidebar, setHideSidebar, updateUser, updateStore, updateStayConnected }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
