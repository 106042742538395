import React, { useState } from 'react';
import ShopkeeperForm from '../../layout/Register/ShopkeeperForm';
import StoreForm from '../../layout/Register/StoreForm';
import CepService from '../../../services/CepService';
import StoreService from '../../../services/StoreService';
import ShopkeeperService from '../../../services/ShopkeeperService';
import { Navigate } from 'react-router';
import './style.scss';
import Swal from 'sweetalert2';

function Register(props) {
    const [formShopkepeerData, setFormShopkepeerData] = useState({
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        cep: '',
        city: '',
        street: '',
        address_number: '',
        neighborhood: '',
        complement: '',
        type: 'shopkepper',
        status: 'pending',
        password: '',
    });
    const [formStoreData, setFormStoreData] = useState({
        companyName: '',
        fantasyName: '',
        username: '',
        cnpj: '',
        cellNumber: '',
        status: 'pending',
        type: 'loja',
        cep: '',
        city: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
    });
    const [submit, setSubmit] = useState(false);
    const [redirect, setRedirect] = useState(false);

    function createUserName(name) {
        // Remover espaços
        let semEspacos = name.replace(/\s/g, '');
        // Remover acentos
        let semAcentos = semEspacos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Converter para minúsculas
        let emMinusculas = semAcentos.toLowerCase();

        return emMinusculas;
    }

    const cepService = new CepService();
    const storeService = new StoreService();
    const shopkeeperService = new ShopkeeperService();

    const handleChangeShopkeeper = async (e) => {
        const { name, value } = e.target;
        let valueConvert = value.replace(/\D/g, '');
        if (name === "cep" && valueConvert.length === 8) {
            const addressFromCep = await cepService.buscarEndereco(valueConvert);
            setFormShopkepeerData(prevState => ({
                ...prevState,
                // eslint-disable-next-line
                ["city"]: addressFromCep._city,
                // eslint-disable-next-line
                ["complement"]: addressFromCep._complement,
                // eslint-disable-next-line
                ["neighborhood"]: addressFromCep._neighborhood,
                // eslint-disable-next-line
                ["address_number"]: addressFromCep._number,
                // eslint-disable-next-line
                ["street"]: addressFromCep._street,
                // eslint-disable-next-line
                ["state"]: addressFromCep._state
            }));
        }
        setFormShopkepeerData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeStore = async (e) => {
        const { name, value } = e.target;
        let valueConvert = value.replace(/\D/g, '');
        if (name === "cep" && valueConvert.length === 8) {
            const addressFromCep = await cepService.buscarEndereco(valueConvert);
            setFormStoreData(prevState => ({
                ...prevState,
                // eslint-disable-next-line
                ["city"]: addressFromCep._city,
                // eslint-disable-next-line
                ["complement"]: addressFromCep._complement,
                // eslint-disable-next-line
                ["neighborhood"]: addressFromCep._neighborhood,
                // eslint-disable-next-line
                ["number"]: addressFromCep._number,
                // eslint-disable-next-line
                ["street"]: addressFromCep._street,
                // eslint-disable-next-line
                ["state"]: addressFromCep._state
            }));
        }
        setFormStoreData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "companyName") {
            setFormStoreData(prevState => ({
                ...prevState,
                // eslint-disable-next-line
                ["fantasyName"]: value,
                // eslint-disable-next-line
                ["username"]: createUserName(value)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setSpinner(true);

        let addressFromStore = {
            cep: formStoreData.cep.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
            city: formStoreData.city,
            street: formStoreData.street,
            number: formStoreData.number,
            neighborhood: formStoreData.neighborhood,
            complement: formStoreData.complement,
            state: formStoreData.state,
        };

        const location = await cepService.buscarLatitudeLongitude(addressFromStore);
        addressFromStore.location = {
            type: "Point",
            coordinates: [location.results[0].geometry.location.lat, location.results[0].geometry.location.lng]
        }

        const formStoreDataToSend = {
            companyName: formStoreData.companyName,
            fantasyName: formStoreData.fantasyName,
            username: formStoreData.username,
            cnpj: formStoreData.cnpj.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
            cellNumber: formStoreData.cellNumber.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
            status: formStoreData.status,
            type: formStoreData.type,
            address: addressFromStore
        };

        setTimeout(async () => {
            try {
                const storeResponse = await storeService.registerStore(formStoreDataToSend);
                if (storeResponse) {
                    const formShopkeeperDataToSend = {
                        name: formShopkepeerData.name,
                        email: formShopkepeerData.email,
                        cpf: formShopkepeerData.cpf.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
                        cellNumber: formShopkepeerData.cellNumber.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
                        address: {
                            cep: formShopkepeerData.cep.replace(/\D/g, ''), // Remove tudo que não é dígito (números)
                            city: formShopkepeerData.city,
                            street: formShopkepeerData.street,
                            address_number: formShopkepeerData.address_number,
                            neighborhood: formShopkepeerData.neighborhood,
                            complement: formShopkepeerData.complement
                        },
                        store: storeResponse.id,
                        type: formShopkepeerData.type,
                        status: formShopkepeerData.status,
                        password: formShopkepeerData.password,
                    };
                    const shopkeeperResponse = await shopkeeperService.registerShopkeeper(formShopkeeperDataToSend);
                    props.setSpinner(false);
                    if (shopkeeperResponse.ok) {
                        setSubmit(true);
                    } else {
                        storeService.removeStore(storeResponse.id);
                    }
                }
            } catch (error) {
                Swal.fire({
                    title: 'Ocorreu um erro!',
                    text: error,
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                    didClose: () => {
                        return;
                    }
                });
            }
        }, 2000);
    };

    if (submit) {
        Swal.fire({
            title: 'Cadastro realizado com sucesso!',
            text: 'Agora é só aguardar um dos nossos administradores aprovar seu cadastro',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
            didClose: () => {
                setSubmit(false);
                setRedirect(true);
            }
        });
    }

    if (redirect) {
        return <Navigate to={'/login'} />;
    }

    return (
        <React.Fragment>
            <div className="register-container">
                <form className="register-form" onSubmit={handleSubmit} data-form>
                    <h1>Faça seu cadastro</h1>
                    <div className="form-container">
                        <ShopkeeperForm formShopkepeerData={formShopkepeerData} handleChangeShopkeeper={handleChangeShopkeeper} />
                        <StoreForm formStoreData={formStoreData} handleChangeStore={handleChangeStore} />
                    </div>
                    <button className="submit-register-form" data-button-submit>
                        Criar Conta
                    </button>
                </form>
            </div>
        </React.Fragment>
    );
}

export default Register;
