import { Component } from "react";
import ErrorMessages from "../../../helper/ErrorMessages";
import MascaraTelefone from "../../../helper/masks/MascaraTelefone";
import Utils from "../../../helper/Utils";
import Field from "../../components/Fieldset/Fieldset";
import TextArea from "../../components/TextArea/TextArea";

class PersonalForm extends Component {
    adicionaMascara(event) {
        if (event.nativeEvent.inputType !== "deleteContentBackward") new MascaraTelefone().adicionaMascaraTelefone(event.target);
    }

    render() {
        return (
            <fieldset className="personal" id="fieldsetAddress" name="fieldset-address">
                <legend className="address-form__legend address-form__legend--minor">Dados do Destinatário</legend>
                <div>
                    <div className={Utils.isDesktop() ? "container-duo" : ""}>
                        <Field content="Nome" labelAnimation="animation--opacity-0" name="name" pattern="^.{6,}$" messageErro={ErrorMessages.personalData.name} required="" />
                        <Field
                            content="Telefone"
                            labelAnimation="animation--opacity-0"
                            name="phone"
                            onInput={this.adicionaMascara}
                            required=""
                            type="tel"
                            messageErro={ErrorMessages.personalData.phone}
                            maxlength="14"
                            pattern="\((\d{2})\)(\d{5})-(\d{4})"
                        />
                    </div>
                    <TextArea content="Observações" labelAnimation="animation--opacity-0" name="message" />
                </div>
            </fieldset>
        );
    }
}

export default PersonalForm;
