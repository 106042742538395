import React from 'react';
import './style.scss';

const Modal = ({ isOpen, onClose, input }) => {

  const handleSubmit = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Insira um novo endereço de origem</h2>
        {input}
        <button onClick={handleSubmit}>OK</button>
      </div>
    </div>
  );
};

export default Modal;
