import React, { useState } from 'react';
import Conversor from '../../../helper/Conversor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheckSquare,
  faUserCheck,
  faTruckLoading,
  faWindowClose,
  faBoxOpen,
  //faUserMinus,
  faClock,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Collapse,
  Box,
  Typography,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import "./style.scss";

const OrderList = ({
  orders,
  handleGroupOrder,
  nextPage,
  lastPage,
  cancelOrder,
  hideActionsButtons,
  title,
  refreshOrders,
  setPendingOrder,
  storeOptions,
  placeholder,
  customStyles,
  defaultValue
}) => {
  const [openRows, setOpenRows] = useState({});

  const handleToggleRow = (orderId) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  const status_icon_map = {
    delivered: <FontAwesomeIcon icon={faCheckSquare} color="#168821ff" />,
    withdrawn: <FontAwesomeIcon icon={faTruckLoading} color="#155bcbff" />,
    arrived: <FontAwesomeIcon icon={faBoxOpen} color="#FFA500" />,
    accept: <FontAwesomeIcon icon={faUserCheck} color="#168821ff" />,
    pending: <FontAwesomeIcon icon={faClock} color="#ffcd07ff" />,
    canceled: <FontAwesomeIcon icon={faWindowClose} color="#e52207ff" />,
  };

  const status_map = {
    delivered: "Entregue",
    withdrawn: "Em Rota de Entrega",
    arrived: "Em Retirada",
    accept: "Aceito",
    pending: "Aguardando Entregador",
    canceled: "Cancelado",
  };

  const payment_map = {
    "credit_card": "CRÉDITO",
    "debit_card": "DÉBITO",
    "Cartão de Crédito": "CRÉDITO",
    "Cartão de Débito": "DÉBITO",
    "cash": "DINHEIRO",
    "paid": "ONLINE",
    "credito": "CRÉDITO",
    "debito": "DÉBITO",
    "dinheiro": "DINHEIRO",
    "Dinheiro": "DINHEIRO",
    "Pago": "ONLINE"
  };

  return (
    <div className="table-orders-container">
      <h1 className="page-title">{title}</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {!hideActionsButtons && <TableCell />}
              <TableCell />
              <TableCell>Cod. de Entrega</TableCell>
              <TableCell>Endereço de Entrega</TableCell>
              <TableCell>Status</TableCell>
              {!hideActionsButtons ? (<TableCell>Ações</TableCell>) : (null)}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <>
                <TableRow key={order._id}>
                  {order.orderDetails.status !== 'delivered' && order.orderDetails.status !== 'canceled' && (
                    <TableCell>
                      {order.orderDetails.isGrouped ? (<FontAwesomeIcon icon={faLayerGroup} title="Agrupamento" style={{ marginLeft: 10, height: 15, color: '#007BFF' }} />) : (
                        <input type="checkbox" name="group-order" id={`group-order-${order._id}`} style={{ marginLeft: 10, cursor: 'pointer', height: 20 }} onChange={(event) => handleGroupOrder(order, event.target.checked)} />
                      )};
                    </TableCell>
                  )}
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleToggleRow(order._id)}
                    >
                      {openRows[order._id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>#{order.orderDetails.deliveryCode}</TableCell>
                  <TableCell>
                    {order.requester.address.street}, nº{order.requester.address.number} - {order.requester.address.neighborhood}, {order.requester.address.city} - {order.requester.address.state}
                  </TableCell>
                  <TableCell>
                    {status_map[order.orderDetails.status]} {status_icon_map[order.orderDetails.status]}
                  </TableCell>
                  {!hideActionsButtons ? (
                    <TableCell>
                      {order.orderDetails.status === "pending" ? (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => cancelOrder(order._id)}
                          style={{ fontSize: 12 }}
                        >
                          Cancelar
                          <FontAwesomeIcon icon={faTimes} title="Cancelar Pedido" style={{ marginLeft: 5, height: 14 }} />
                        </Button>
                      ) : (null)}
                      {/* 
                      OBSOLETO
                      {order.orderDetails.status !== "pending" && order.orderDetails.status !== "withdrawn" ? (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => setPendingOrder(order._id, order.deliveryman.id)}
                          style={{ fontSize: 12 }}
                        >
                          Cancelar
                          <FontAwesomeIcon icon={faUserMinus} title="Cancelar Entrega" style={{ marginLeft: 5, height: 14 }} />
                        </Button>
                      ) : (null)} */}
                    </TableCell>
                  ) : (null)}
                </TableRow>

                {/* Linha expandida com Collapse */}
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={openRows[order._id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Detalhes da Entrega
                        </Typography>
                        <Typography variant="body2" className='collapse-order'>
                          <div className="collapse-order-box">
                            <h4>Informações Gerais</h4>
                            <hr />
                            <p className="collapse-order-line">Solicitado por: {order.store.companyName}</p>
                            <p className="collapse-order-line">Data de Solicitação: {Conversor.converterData(order.orderDetails.createdAt)}</p>
                            <p className="collapse-order-line">Nome do Cliente: {order.requester.name} </p>
                            <p className="collapse-order-line">Telefone do Cliente: {Conversor.converterFormatoTelefone(String(order.requester.cellNumber))}</p>
                          </div>
                          <div className="collapse-order-box">
                            <h4>Dados do Pedido</h4>
                            <hr />
                            <p className="collapse-order-line">Pedido IFood: {order.orderDetails.isIfood ? 'Sim' : 'Não'}</p>
                            {order.orderDetails.isIfood ? (
                              <>
                                <p className="collapse-order-line">ID IFood do pedido: {order.orderDetails.ifoodDetails.displayId}</p>
                                <p className="collapse-order-line">Forma de Pagamento:
                                  {payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (
                                    <span>
                                      {` DINHEIRO ${order.orderDetails.payment.change > 0 ?
                                        '(TROCO PARA ' + Conversor.converterStringReal(order.orderDetails.payment.change) + ')' : ('(Não precisa de troco)')}`}
                                    </span>
                                  ) : (
                                    <span>
                                      {` ${order.orderDetails.payment.brand ? order.orderDetails.payment.brand + ` ` : ``}`}
                                      {payment_map[order.orderDetails.payment.type]}
                                    </span>
                                  )}
                                </p>
                              </>
                            ) : (null)}
                            <p className="collapse-order-line">Valor do Pedido: {Conversor.converterStringReal(order.orderDetails.payment.price)}</p>
                            <p className="collapse-order-line">Mensagem: {order.orderDetails.message ? order.orderDetails.message : "Não informado"}</p>
                          </div>
                          <div className="collapse-order-box">
                            <h4>Dados da Entrega</h4>
                            <hr />
                            <p className="collapse-order-line">Entregador: {order.deliveryman ? order.deliveryman.name : "Aguardando..."}</p>
                            <p className="collapse-order-line">Tel. do Entregador: {order.deliveryman ? Conversor.converterFormatoTelefone(String(order.deliveryman.cellNumber)) : "Aguardando..."}</p>
                            {order.orderDetails.isIfood ? (
                              <>
                                <p className="collapse-order-line">Cod. Confirmação de Coleta: {order.orderDetails.ifoodDetails.delivery.pickupCode}</p>
                                <p className="collapse-order-line">Localizador IFood: {order.orderDetails.ifoodDetails.localizer}</p>
                              </>
                            ) : (null)}
                            <p className="collapse-order-line">Frete: {Conversor.converterStringReal(order.orderDetails.payment.shipping)} </p>
                            <p className="collapse-order-line">Taxa de Serviço: {Conversor.converterStringReal(order.orderDetails.payment.serviceFee)} </p>
                            <p className="collapse-order-line">Total: {Conversor.converterStringReal(order.orderDetails.payment.orderAmount)} </p>
                          </div>
                        </Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div className="pagination-buttons">
        <Button onClick={lastPage}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button onClick={nextPage}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div> */}
    </div>
  );
};

export default OrderList;
