import React, { Component } from 'react';
import { Navigate } from 'react-router';
import Button from '../../components/Button/Button';
import Map from '../../components/Map/Map';
import Shipping from '../../components/Shipping/Shipping';
import Validation from '../../../helper/validation';
import Address from '../../../model/Address';
import ShippingService from '../../../services/ShippingService';
import MascaraCep from '../../../helper/masks/CepMasks';
import CepService from '../../../services/CepService';
import { UserContext } from '../../context/UserContext';

class AddressForm extends Component {
  static address = '';
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showOthers: false,
      cep: '',
      city: '',
      neighborhood: '',
      street: '',
      complement: '',
      state: '',
      number: '',
      market: { lat: -10.940091795313114, lng: -37.06601116747594 },
      zoom: 14,
      exibir: true,
      shippingCalculado: false,
    };

    this._isMounted = false; // Flag to track component mount status

    this.shipping = { anterior: undefined, atual: 0 };
    this.$ = document.querySelector.bind(document);
    this.props = props;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.calculateShipping = this.calculateShipping.bind(this);
    this.cepService = new CepService();
  }

  componentDidMount() {
    this._isMounted = true; // Set flag to true when the component mounts
  }

  componentWillUnmount() {
    this._isMounted = false; // Set flag to false when the component unmounts
  }

  setExibir(valor) {
    if (this._isMounted) {
      this.setState({ exibir: valor });
    }
  }

  setMarket(valor) {
    if (this._isMounted) {
      this.setState({ market: valor });
    }
  }

  async updateMapPosition(mapCoordinates) {
    if (this._isMounted) {
      this.setState({ zoom: 16 });
      this.props.setCoordenadas(mapCoordinates);
      this.setMarket(mapCoordinates);
      this.setExibir(false);
    }
  }

  async calculateShipping(mapCoordinates) {
    console.log(mapCoordinates)
    await this.updateMapPosition(mapCoordinates);
    if (this._isMounted) {
      console.log(this.getAddress(this.state))
      this.address = this.getAddress(this.state);
      this.props.setAddress(this.address);
      this.shipping.anterior = this.props.shipping;
      let newShipping;
      newShipping = await new ShippingService().fetchDataShipping(this.props.originMarket.lat, this.props.originMarket.lng, mapCoordinates.lat, mapCoordinates.lng);
      console.log(newShipping);
      this.props.changeShipping(newShipping);
      this.setState({ shippingCalculado: true });
      this.props.setCoordenadas(this.state.market);
      this.props.setDistance(newShipping._distanciaMetros);
      this.props.setTime(newShipping._duracaoMinutos);
    }
  }

  handleInputChange(event) {
    this.props.changeShipping(0);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (this._isMounted) {
      this.setState({
        [name]: value,
      });

      const result = Validation.validAddressFields(this.$('[data-fieldset-address]').elements);
      if (result) Button.activeButton(this.$('[data-calc-shipping]'));
    }
  }

  setAddress(newAddress) {
    if (this._isMounted) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.cep = newAddress.cep;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.city = newAddress.city;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.neighborhood = newAddress.neighborhood;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.street = newAddress.street;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.complement = newAddress.complement;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.state = newAddress.state;
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.number = newAddress.number;
    }
  }

  getAddress({ cep, city, neighborhood, street, complement, state, number, market }) {
    return new Address(cep, city, neighborhood, street, complement, state, number, market.lat, market.lng);
  }

  adicionaMascara(event) {
    if (event.nativeEvent.inputType !== 'deleteContentBackward') {
      new MascaraCep().adicionaMascaraCep(event.target);
    }
  }

  async getAddressFromCep() {
    if (this.state.cep.length === 9 && this._isMounted) {
      try {
        const endereco = await this.cepService.buscarEndereco(this.state.cep);
        this.setAddressFromCep(endereco);
      } catch {
        console.log('CEP inválido');
      }
    }
  }

  setAddressFromCep({ city, neighborhood, street, state }) {
    if (this._isMounted) {
      this.setState({
        city,
        neighborhood,
        street,
        state,
      });
    }
  }

  render() {
    const { selectedStore } = this.context;
    const storeAddress = this.props.originAddress !== '' ? this.props.originAddress : selectedStore.address;
    const store = selectedStore;

    const storeAddressNumber = storeAddress.number ? storeAddress.number : 's/n';
    const storeAddressComplement = storeAddress.complement ? ', ' + storeAddress.complement + ', ' : ', ';

    const handleOriginMarket = (market) => {
      this.props.setOriginMarket(market);
    }

    if (this.state.redirect) {
      return <Navigate to="/personal" />;
    }

    return (
      <fieldset
        className="address-form"
        id="fieldsetAddress"
        name="fieldset-address"
        data-fieldset-address
      >
        <section className='store-address'>
          <div className={`store-address-info-container hide-select-store-info`}>
            <h3>Loja: {store.companyName}</h3>
            <span>Endereço de Retirada: {storeAddress.street + ', ' + storeAddressNumber + ', ' + storeAddress.neighborhood + storeAddressComplement + storeAddress.city + ', ' + storeAddress.state}</span>
          </div>
        </section>
        <Map
          address={this.props.address}
          handler={this.setAddress}
          setMarket={this.setMarket.bind(this)}
          market={this.state.market}
          zoom={this.state.zoom}
          exibir={this.state.exibir}
          setExibir={this.setExibir.bind(this)}
          calculateShipping={this.calculateShipping.bind(this)}
          setOriginAddress={this.props.setOriginAddress}
          handleOriginMarket={handleOriginMarket}
          originMarket={this.props.originMarket}
          changeMarket={this.props.changeMarket}
          requestsLength={this.props.requests ? this.props.requests.length : 0}
          distance={this.props.distance}
          time={this.props.time}
        />
        <div className="address-form__container-shipping">
          <Shipping showShipping="" value={this.props.shipping.valorCorridaCentavos} text="Valor do frete" />
        </div>
      </fieldset>
    );
  }
}

export default AddressForm;
