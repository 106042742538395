import { Component } from "react";

class Error extends Component {
    render() { 
        return ( 
            <h1>Opa</h1>
         );
    }
}
 
export default Error;