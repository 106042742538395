import CaracteresEspeciais from "./../helper/CaracteresEspeciais";

export default class Address {
    constructor(cep, city, neighborhood, street, complement, state, number, latitude, longitude) {
        this._cep = cep;
        this._city = city;
        this._neighborhood = neighborhood;
        this._street = street;
        this._complement = complement;
        this._state = state;
        this._number = number;
        this._latitude = latitude;
        this._longitude = longitude;
    }

    toString() {
        return `${CaracteresEspeciais.remover(this.street)}, ${this.number} - ${CaracteresEspeciais.remover(this.neighborhood)}, ${CaracteresEspeciais.remover(this.city)} - ${this.state}, ${this.cep}`;
    }

    get cep() {
        return this._cep;
    }

    get city() {
        return this._city;
    }

    get neighborhood() {
        return this._neighborhood;
    }

    get street() {
        return this._street;
    }

    get complement() {
        return this._complement;
    }

    get state() {
        return this._state;
    }

    get number() {
        return this._number;
    }

    get latitude() {
        return this._latitude;
    }

    get longitude() {
        return this._longitude;
    }
}
