export default class Requester {
    constructor( 
        address,
        name, 
        phone,
        lat,
        lng
        ) 
    {
        this.name = name;
        this.cellNumber = phone;
        this.address = {
            "cep": address.cep,
            "street": address.street,
            "number": address.number,
            "neighborhood": address.neighborhood,
            "complement": address.complement,
            "city": address.city,
            "state": address.state,
            "location": {
                "type": "Point",
                "coordinates": [lat, lng]
            }
        }
    }
}
