import React, { useState, useEffect } from "react";

function OrderCountdown({ createdAt }) {
  const [timeLeft, setTimeLeft] = useState(calculateCountDown(createdAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateCountDown(createdAt));
    }, 1000);

    return () => clearInterval(interval);
  }, [createdAt]);

  return (
    <div className="wait-time-box" style={timeLeft > 3 ? {backgroundColor: '#F2F2F2'} : {backgroundColor: '#EF5753'}}>
      <p className="count-time" style={timeLeft > 3 ? {color: '#565656'} : {color: '#fff'}}>{timeLeft}</p>
      <p className="count-label" style={timeLeft > 3 ? {color: '#565656'} : {color: '#fff'}}>min</p>
    </div>
  );
}

function calculateCountDown(createdAt) {
  const orderTime = new Date(createdAt);
  const now = new Date();
  const diffInMilliseconds = orderTime.getTime() + 6 * 60000 - now.getTime();
  const diffInSeconds = Math.max(0, Math.floor(diffInMilliseconds / 1000));
  return Math.floor(diffInSeconds / 60);
}

export default OrderCountdown;
