import { useEffect, useState } from 'react';
import simpleMaskMoney from 'simple-mask-money';
import Conversor from '../../../helper/Conversor';
import MaskMoney from '../../../helper/masks/MaskMoney';

function Shipping({ value, showShipping, text }) {
  // eslint-disable-next-line
  const [readOnly, setReadOnly] = useState(true);
  const [shipping, setShipping] = useState(value);

  useEffect(() => {
    MaskMoney.setMask('#shipping');
    document.querySelector("#shipping").blur();
  }, []);

  useEffect(() => {
    setShipping(Conversor.converterStringReal(value));
  }, [value]);

  function setMoneyMask() {
    const args = {
      allowNegative: false,
      negativeSignAfter: false,
      prefix: 'R$ ',
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ',',
      thousandsSeparator: '.',
      cursor: 'end',
    };
    simpleMaskMoney.setMask('#shipping', args);
  }

  return (
    <section className={`shipping ${showShipping}`}>
      <label>
        <input
          className="shipping__input"
          name="shipping"
          id="shipping"
          type="text"
          data-frete-valor
          value={shipping}
          onChange={(event) => setShipping(event.target.value)}
          readOnly={readOnly}
          onLoad={setMoneyMask}
        />
        <div className="frete-error" data-frete-error>
          <p className="frete-error__mensagem"></p>
        </div>
        <p className="shipping__label">{text}</p>
      </label>
    </section>
  );
}

export default Shipping;
