export default class ErrorMessages {
    //!corrigir nome class

    static personalData = {
        name: `Nome inválido`,
        phone: `Contato inválido`,
    };
    static address = {
        cep: `Cep inválido`,
        street: `Nome de rua inválido`,
        neighborhood: `Nome do bairro inválido`,
        city: `Nome de cidade inválido`,
        state: `UF inválido`,
        complement: "Informe um complemento válido",
        number: `Preencha os campos anteriores`,
    };

    static shipping = {
        main: "Não foi possível calcular frete.",
        secondary: "Informe todos os campos obrigatórios",
    };

    static payment = {
        orderNumber: `Número de pedido inválido`,
        change: `Informe um valor de troco válido`,
    };

    static order = `Desculpa, erro ao calcular corrida solicitada`;
}
