import HttpService from "./HttpService.js";

export default class IfoodService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/ifood`;
    }

    async getMerchantInterruptions(storeId) {
        const response = await this._http.get(this._urlBase+`/merchant/${storeId}/interruptions`);
        return response;
    }

    async createMerchantInterruption(storeId, requestBody) {
        const response = await this._http.post(this._urlBase+`/merchant/${storeId}/interruptions`, requestBody);
        return response;
    }

    async deleteMerchantInterruption(storeId, interruptionId) {
        const response = await this._http.delete(this._urlBase+`/merchant/${storeId}/interruptions/${interruptionId}`);
        return response;
    }

    async getHandShakeDisputes() {
        const response = await this._http.get(this._urlBase+'/handShakeDispute');
        return response;
    }

    async acceptHandShakeDispute(storeId, disputeId) {
        const response = await this._http.post(this._urlBase+`/handShakeDispute/${storeId}/${disputeId}/accept`);
        return response;
    }

    async rejectHandShakeDispute(storeId, disputeId) {
        const response = await this._http.post(this._urlBase+`/handShakeDispute/${storeId}/${disputeId}/reject`);
        return response;
    }

    async getDeliveryAddressChangeRequests() {
        const response = await this._http.get(this._urlBase+'/addressChangeRequest');
        return response;
    }

    async acceptDeliveryAddressChangeRequest(storeId, orderId) {
        const response = await this._http.post(this._urlBase+`/addressChangeRequest/${storeId}/${orderId}/accept`);
        return response;
    }

    async rejectDeliveryAddressChangeRequest(storeId, orderId) {
        const response = await this._http.post(this._urlBase+`/addressChangeRequest/${storeId}/${orderId}/reject`);
        return response;
    }

    async getIfoodIntegrations() {
        const response = await this._http.get(this._urlBase+'/getIfoodIntegrations');
        return response;
    }

    async storeIfoodIntegrations(store, ifoodId) {
        const response = await this._http.post(this._urlBase+'/storeIfoodIntegrations', {
            store: store,
            ifoodId: ifoodId,
            status: "PENDING"
        });
        return response;
    }

    async aproveIfoodIntegration(integrationId) {
        const response = await this._http.put(this._urlBase+`/updateIfoodIntegrations/${integrationId}`, {
            status: "INTEGRATED"
        });
        return response;
    }
}
