import { Component } from "react";
import Field from "../../components/Fieldset/Fieldset";
import Select from "../../components/Select/Select";

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableChange: true,
        };
        this.props = props;
    }

    setDisableChange(value) {
        this.setState({ disableChange: value });
        if(value !== "dinheiro") document.querySelector("#change").value = 0;
    }

    render() {
        return (
            <fieldset className="payment">
                <legend className="address-form__legend address-form__legend--minor">Dados do Pagamento</legend>
                <Field content="Valor do pedido" labelAnimation="animation--opacity-0" name="price" id="price" inputMode="numeric" placeholder="" required={true}/>
                <Select
                    content="Forma de pagamento"
                    name="payment"
                    options={[
                        { name: "Dinheiro", value: "dinheiro" },
                        { name: "Cartão de crédito", value: "credito" },
                        { name: "Cartão de débito", value: "debito" },
                        { name: "Pago", value: "Pago" },
                        { name: "Apenas receber entrega", value: "Apenas entrega" },
                    ]}
                    labelAnimation="animation--opacity-0"
                    paymentValue={this.state.paymentValue}
                    setDisableChange={this.setDisableChange.bind(this)}
                />
                <Field content="Troco" labelAnimation="animation--opacity-0" blockChange={this.state.disableChange} name="change" id="change" inputMode="numeric"  />
            </fieldset>
        );
    }
}

export default PaymentForm;
