import HttpService from "./HttpService.js";

export default class StoreService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/store`;
    }

    async getStores() {
        const storeResponse = await this._http.get(this._urlBase);
        return storeResponse;
    }

    async showStore(id) {
        const storeResponse = await this._http.get(this._urlBase+`/${id}`);
        return storeResponse;
    }

    async registerStore(data) {
        const resposta = await fetch(this._urlBase, {
            headers: { "Content-type": "application/json", "Access-Control-Allow-Origin": "*" },
            method: "POST",
            body: JSON.stringify(data),
        });
        return resposta.json();
    }

    async removeStore(id) {
        const response = await this._http.delete(this._urlBase+`/${id}`);
        return response;
    }
}
