export default class OrderDetails {
    constructor(
        price, 
        shipping, 
        type, 
        installments, 
        change, 
        message, 
        distance
        )
    {
        this.payment = {
            "price": price,
            "shipping": shipping,
            "type": type,
            "installments": installments,
            "change": change
        };
        this.message = message;
        this.distance = distance
    }
}