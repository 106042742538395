import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animation from "./../../../assets/json/delivery-man.json";
import { Navigate } from "react-router";
import "./style.scss";
import logo from "./../../../assets/img/motum-alternative.svg";

const Conclusion = () => {
    const defaultOptions = {
        isPaused: false,
        direction: -1,
        isStopped: false,
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        document.title = "Entrega da Vizinhança";
        const timer = setTimeout(() => setRedirect(true), 3000);
        return () => clearTimeout(timer);
    }, []);

    if (redirect) return <Navigate to={"/home"} />;

    return (
        <main>
            <div className="conclusion">
              <img className="conclusion__logo" src={logo} alt="Logo Motum"/>
                <div className="conclusion__container">
                    <Lottie options={defaultOptions} width={"100%"} height={"100%"} />
                </div>
                <div>
                    <h1 className="conclusion__title">Tudo certo!</h1>
                    <p className="conclusion__subtitle">Você receberá seu pedido em breve</p>
                </div>
            </div>
        </main>
    );
};

export default React.memo(Conclusion);
