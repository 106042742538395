import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Navigate } from 'react-router';
import OrderService from '../../../services/OrderService';
import OrderList from '../../layout/OrderList/OrderList';
import './style.scss';

const CanceledDeliveries = (props) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isChangePage, setIsChangePage] = useState(false);
  const { selectedStore, user } = useContext(UserContext);
  const storeOptions = [];

  async function loadOrders(isChangeStore = false) {
    if (loading) {
      return;
    }
    setLoading(true);
    props.setSpinner(true);
    setTimeout(async () => {
      const orderService = new OrderService();
      const response = await orderService.getOrderByStatus(currentPage, selectedStore._id, "canceled");
      if (response.orders.length > 0 || isChangeStore) {
        setOrders(response.orders);
      } else {
        if (isChangePage) {
          setCurrentPage(currentPage - 1)
        }
      }
      setIsChangePage(false);
      props.setSpinner(false)
    }, 2000);
  }

  function nextPage() {
    setIsChangePage(true);
    setCurrentPage(currentPage + 1);
    setLoading(false);
  }

  function lastPage() {
    if (currentPage !== 1) {
      setIsChangePage(true);
      setCurrentPage(currentPage - 1)
      setLoading(false);
    }
  }

  const refreshOrders = () => {
    setLoading(false);
    loadOrders();
  }

  useEffect(() => {
    loadOrders();
    document.title = `Entregas Canceladas | Motum`;
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    loadOrders(true);
    //eslint-disable-next-line
  }, [selectedStore]);


  if (!user) {
    return <Navigate to={'/login'} />;
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  return (
    <React.Fragment>
      <div className="content-container canceled-deliveries-container">
        <OrderList
          orders={orders}
          title={"Entregas Canceladas"}
          nextPage={nextPage}
          lastPage={lastPage}
          hideActionsButtons={true}
          setLoading={setLoading}
          loadOrders={loadOrders}
          refreshOrders={refreshOrders}
          storeOptions={storeOptions}
          placeholder={'Selecione uma Loja'}
          customStyles={customStyles}
          defaultValue={{ value: selectedStore._id, label: selectedStore.companyName }}
        />
      </div>
    </React.Fragment>
  );
};

export default CanceledDeliveries;
