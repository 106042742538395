import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router';
import OrderService from '../../../services/OrderService';
import OrderList from '../../layout/OrderList/OrderList';
import { UserContext } from '../../context/UserContext';
import { useSocket } from '../../context/SocketContext';
import Swal from 'sweetalert2';
import Notification from '../../components/Notification/Notification';
import './style.scss';
import { Button } from '@mui/material';
import OrderGroupingService from '../../../services/OrderGroupingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';


const FollowUp = (props) => {
  const [orders, setOrders] = useState([]);
  const [groupOrders, setGroupOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isChangePage, setIsChangePage] = useState(false);
  const { selectedStore, user } = useContext(UserContext);
  const socket = useSocket();
  const storeOptions = [];

  function handleGroupOrder(order, isChecked) {
    if (isChecked) setGroupOrders((prevOrders) => [...prevOrders, order]);
    else setGroupOrders((prevOrders) => prevOrders.filter((o) => o._id !== order._id));
  }

  function renameKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => renameKeys(item));
    }

    const newObj = {};
    for (let key in obj) {
      let newKey = key === '_id' ? 'id' : key;
      newObj[newKey] = renameKeys(obj[key]);
    }
    return newObj;
  }

  async function createOrdersGrouping() {
    setLoading(true);
    props.setSpinner(true);
    setTimeout(async () => {
      try {
        const orderGroupingService = new OrderGroupingService();
        const store = renameKeys(selectedStore);
        const response = await orderGroupingService.createOrderGrouping(store, groupOrders);
        props.setSpinner(false);
        props.setTextSpinner('');
        if (response) {
          Swal.fire({
            title: 'Entregas agrupadas com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: 'Ocorreu um erro ao agrupar as entregas!',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
          })
        }
        setLoading(false);
        setGroupOrders([]);
        loadOrders();
      } catch (error) {
        Swal.fire({
          title: 'Ocorreu um erro ao agrupar as entregas!',
          text: error,
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        })
      }
    }, 2000);
  }

  async function loadOrders(isChangeStore = false) {
    if (loading) {
      return;
    }
    setLoading(true);
    props.setSpinner(true);
    setTimeout(async () => {
      const orderService = new OrderService();
      const response = await orderService.getOrders(currentPage, selectedStore._id);
      if (response.orders.length > 0 || isChangeStore) {
        setOrders(response.orders);
      } else {
        if (isChangePage) {
          setCurrentPage(currentPage - 1)
        }
      }
      setIsChangePage(false);
      props.setSpinner(false);
      props.setTextSpinner('');
    }, 2000);
  }

  function nextPage() {
    setIsChangePage(true);
    setCurrentPage(currentPage + 1);
    setLoading(false);
  }

  function lastPage() {
    if (currentPage !== 1) {
      setIsChangePage(true);
      setCurrentPage(currentPage - 1)
      setLoading(false);
    }
  }

  const refreshOrders = () => {
    setLoading(false);
    loadOrders();
  }

  async function setPendingOrder(order_id, deliveryman_id) {
    const result = await Swal.fire({
      title: 'Deseja cancelar essa entrega?',
      text: 'O entregador atual será desvinculado e o pedido aguardará um novo entregador!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#17a2b8',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Voltar',
    });

    if (result.isConfirmed) {
      const orderService = new OrderService();
      const response = await orderService.setPendingOrder(order_id, deliveryman_id);

      if (response) {
        setLoading(false);
        Swal.fire({
          title: 'Entrega cancelada com sucesso!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
        return response;
      } else {
        Swal.fire({
          title: 'Ocorreu um erro ao cancelar a entrega!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        })
      }
    }
  }

  async function cancelOrder(order_id) {
    const result = await Swal.fire({
      title: 'Deseja cancelar esse pedido?',
      text: 'Essa ação é irreversível!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#17a2b8',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Voltar',
    });

    if (result.isConfirmed) {
      const orderService = new OrderService();
      const response = await orderService.cancelOrder(order_id);
      if (response.ok) {
        Swal.fire({
          title: 'Pedido cancelado com sucesso!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
          didClose: () => {
            setLoading(false);
          }
        });
        return response;
      } else {
        Swal.fire({
          title: 'Ocorreu um erro ao cancelar o pedido!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        })
      }
    }
  }

  useEffect(() => {
    loadOrders(true);
    document.title = `Acompanhamento | Motum`;
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    loadOrders(true);
    // eslint-disable-next-line
  }, [selectedStore]);

  const [orderQueue, setOrderQueue] = useState([]);
  const [processing, setProcessing] = useState(false);

  const schedulePushNotification = (data) => {
    setNotificationData(data);
    setShowNotification(true);
    setLoading(false);
    setTimeout(() => {
      setShowNotification(false);
      loadOrders();
      setOrderQueue((prevQueue) => prevQueue.slice(1));
    }, 2000);
    setProcessing(false);
  }

  useEffect(() => {
    const handleUpdateOrder = (data) => {
      setOrderQueue((prevQueue) => [...prevQueue, data]);
    }

    socket.on('DELIVERED_ORDER', handleUpdateOrder);
    socket.on('WITHDRAWN_ORDER', handleUpdateOrder);
    socket.on('ARRIVED_ORDER', handleUpdateOrder);
    socket.on('ACCEPT_ORDER', handleUpdateOrder);
    socket.on('LOADING', loadOrders);

    return () => {
      socket.off('DELIVERED_ORDER', handleUpdateOrder);
      socket.off('WITHDRAWN_ORDER', handleUpdateOrder);
      socket.off('ARRIVED_ORDER', handleUpdateOrder);
      socket.off('ACCEPT_ORDER', handleUpdateOrder);
      socket.off('ACCEPT_ORDER', handleUpdateOrder);
      socket.off('LOADING', loadOrders);
    };
    //eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    // Process the order queue when it's not empty and not already being processed
    if (orderQueue.length > 0 && !processing && !showNotification) {
      // Set processing to true to prevent simultaneous processing
      setProcessing(true);

      // Get the first order in the queue
      const orderToProcess = orderQueue[0];

      // Execute your function for the order (e.g., schedulePushNotification)
      schedulePushNotification(orderToProcess);
    }
    // eslint-disable-next-line
  }, [orderQueue, processing, showNotification]);

  if (!user) {
    return <Navigate to={'/login'} />;
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  return (
    <React.Fragment>
      <div className="content-container">
        <div className="content-buttons-header">
          {groupOrders.length > 0 ? (
            <Button
              variant="contained"
              onClick={createOrdersGrouping}
            >
              Criar Agrupamento
            </Button>
          ) : (<div></div>)}
          <div className="group-label" style={{ marginBottom: -30, fontSize: 14 }}>
            <FontAwesomeIcon icon={faLayerGroup} title="Agrupamento" style={{ marginRight: 10, height: 15, color: '#1D5278' }} />
            Entregas agrupadas
          </div>
        </div>
        <OrderList
          orders={orders}
          handleGroupOrder={handleGroupOrder}
          title={"Acompanhamento de Entregas"}
          nextPage={nextPage}
          lastPage={lastPage}
          cancelOrder={cancelOrder}
          hideActionsButtons={false}
          setLoading={setLoading}
          loadOrders={loadOrders}
          refreshOrders={refreshOrders}
          setPendingOrder={setPendingOrder}
          storeOptions={storeOptions}
          placeholder={'Selecione uma Loja'}
          customStyles={customStyles}
          defaultValue={{ value: selectedStore._id, label: selectedStore.companyName }}
        />
        {showNotification ? (<Notification notificationData={notificationData} />) : ("")}
      </div>
    </React.Fragment>
  );
};

export default FollowUp;
