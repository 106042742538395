import Shipping from '../model/Shipping.js';
import HttpService from './HttpService.js';

export default class ShippingService {
  constructor() {
    this._http = new HttpService();
    this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/calcularDistancia`; //! //api
    //this._urlBase = 'http://localhost:8080/api/calcularDistancia'; //! //api
  }

  async fetchDataShipping(origemLat, origemLng, destinoLat, destinoLng) {
    const dadosCorrida = await this._http.get(
      `${this._urlBase}?origemLat=${origemLat.toString()}&origemLng=${origemLng.toString()}&destinoLat=${destinoLat}&destinoLng=${destinoLng}`
    );
    return this._createShipping(dadosCorrida);
  }

  _createShipping(dadosCorrida) {
    return new Shipping(dadosCorrida);
  }
}
