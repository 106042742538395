import HttpService from "./HttpService.js";

export default class LoginService {
    constructor() {
        this._http = new HttpService();
        this._baseUrl = `${process.env.REACT_APP_DELIVERIES_API}/users/login`;
    }

    async sendLoginRequest(encodedCredentials) {
        const response = await fetch(this._baseUrl, {
            method: 'GET',
            headers: {
                Authorization: `Basic ${encodedCredentials}`,
            },
        });
        const data = await response.json();
        
        if (!response.ok) {
            throw new Error(data.error);
        }

        return data;
        
    }
}
