export default class Conversor {
    static converterReal(valorTexto) {
        return parseFloat(valorTexto.replace("R$", "").replace(",", ".").replace(" ", ""));
    }

    static converterTelefone(telefoneTexto) {
        return parseInt(telefoneTexto.replace("(", "").replace(")", "").replace("-", ""));
    }

    static converterCep(cepTexto) {
        return parseInt(cepTexto.replace("-", ""));
    }

    static converterCentavosReal(shippingValue) {
        return shippingValue ? `R$ ${(shippingValue / 100).toFixed(2)}` : 'R$ 0,00';
    }

    static converterStringReal(value) {
        return value ? `R$ ${value.toFixed(2)}` : 'R$ 0,00';
    }

    static converterData(dataString) {
        const data = new Date(dataString);
        const dia = data.getDate();
        const mes = data.getMonth() + 1;
        const ano = data.getFullYear();
        const horas = data.getHours();
        const minutos = data.getMinutes();
        const formatoFinal = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${ano} às ${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
        return formatoFinal;
    }

    static converterSomenteData(dataString) {
        if(dataString) {
            const data = new Date(dataString);
            const dia = data.getDate();
            const mes = data.getMonth() + 1;
            const ano = data.getFullYear();
            const formatoFinal = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${ano}`;
            return formatoFinal;
        }else {
            return "Aguardando";
        }
    }

    static converterSomenteHora(dataString) {
        if(dataString) {
            const data = new Date(dataString);
            const horas = data.getHours();
            const minutos = data.getMinutes();
            const formatoFinal = `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
            return formatoFinal;
        }else {
            return "...";
        }
    }

    static converterDistanciaParaKm(distanciaMetros) {
        const distanciaKm = distanciaMetros / 1000;
        return distanciaKm.toFixed(1) + ' km';
    }

    static converterFormatoTelefone(telefone) {
        const regex = /^(\d{2})(\d{0,1})(\d{4})(\d{4})$/;
        const matches = telefone.match(regex);
      
        if (matches) {
          const parte1 = matches[1];
          const parte2 = matches[2] ? ` ${matches[2]}` : '';
          const parte3 = matches[3];
          const parte4 = `-${matches[4]}`;
          
          const formatoConvertido = `(${parte1})${parte2} ${parte3}${parte4}`;
          return formatoConvertido;
        } else {
          // Retornar o número original se não corresponder aos formatos esperados
          return telefone;
        }
    }

    static converterCpf(cpf) {
        cpf = cpf.replace(/\D/g, '');
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    
        return cpf;
    }

    static converterCNPJ(cnpj) {
        // Remove caracteres não numéricos
        cnpj = cnpj.replace(/\D/g, '');
    
        // Adiciona os pontos e barras de formatação
        cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
        cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
    
        return cnpj;
    }

    static converterCEP(cep) {
        // Verifica se o CEP possui o tamanho correto
        if (cep.length === 8) {
            // Formata o CEP adicionando traços
            return cep.substring(0, 5) + "-" + cep.substring(5);
        } else {
            // Se o tamanho do CEP for diferente de 8, retorna o CEP original
            return cep;
        }
    }
}
