import React, { useContext, useState } from "react";
import section1 from "../img/homepage.svg"
import section3 from "../img/pacote.svg"
import section4 from "../img/celular.svg"
import { IconButton, Box, Button, styled, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import BoltIcon from '@mui/icons-material/Bolt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function HomePage(props){
    const [currentIndex, setCurrentIndex] = useState(0);

    const carouselItems = [
        {
          title: "Motum Hub",
          content: "Comece a ter envios rápidos e econômicos, a partir de R$5,49",
          icon: <BoltIcon/>
        },
        {
          title: "Motum Cidades",
          content: "Envie Para Qualquer Lugar do Brasil com Motum Cidades",
          icon: <LocationOnIcon/>
        },
        {
          title: "Motum Xpress",
          content: "Entregando seu pedido em até 30 minutos",
          icon: <AccessTimeFilledIcon/>
        },
        {
          title: "Integrações",
          content: "Adicione os seus pedidos automaticamente",
          icon: ""
        },
      ];
    
      const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      };
    
      const prevItem = () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length
        );
      };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
          backgroundColor: '#1A2027',
        }),
      }));

    return (
        <React.Fragment>
        <Box sx={{width:"100%", height:"664px", backgroundImage:""}}>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={4} className="primary-section-1">
            <Typography className="titulo-home" variant="h2" gutterBottom>
                A BLACK FRIDAY chegou na MOTUM!
                <Typography className="subtitulo-home" variant="h5" gutterBottom>
                Todas as entregas com até 30% de desconto, faça já o seu pedido.
                </Typography>
                <Button className="send-now-button" sx={{ mb: "12px" }} >Enviar Agora</Button>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: "50px", maxWidth: "218px", ml: "5px"  }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Rastrear encomenda"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Typography>
            
            </Grid>
            <Grid size={8} className="second-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-1" sx={{ backgroundImage: `url(${section1})`, backgroundSize: "cover"}}>
            <Typography className="titulo-home-lg" variant="h2" gutterBottom>
                A BLACK FRIDAY chegou na MOTUM!
                <Typography className="subtitulo-home-lg" variant="h5" gutterBottom>
                Todas as entregas com até 30% de desconto, faça já o seu pedido.
                </Typography>
                <Button className="send-now-button" sx={{ mb: "12px" }} >Enviar Agora</Button>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: "50px", maxWidth: "218px", ml: "5px"  }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Rastrear encomenda"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Typography>
            
            </Grid>

            </Grid>
            
            </Box>
            <Box sx={{width:"100%", height:"574px", backgroundColor:"#F2F2F2"}}>
                <Typography className="titulo-secondary-section" variant="h2" gutterBottom>
                A Motum Entrega onde seu cliente estiver!
                </Typography>
                <Grid sx={{display:"flex", justifyContent:"center", '@media (max-width: 1399px)': { display: 'none' }}}>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <Typography className="card-title" variant="h5" component="div">
                                <BoltIcon/>
                                Motum Hub
                                </Typography>
                                <Typography className="card-content" variant="body1">
                                Comece a ter envios rápidos e econômicos,  a partir de R$5,49
                                </Typography>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <Button className="card-button" size="small">Saiba mais</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <Typography className="card-title" variant="h5" component="div">
                                <LocationOnIcon/>
                                Motum Cidades
                                </Typography>
                                <Typography className="card-content" variant="body1">
                                Envie Para Qualquer Lugar do Brasil com Motum Cidades
                                </Typography>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <Button className="card-button" size="small">Saiba mais</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <Typography className="card-title" variant="h5" component="div">
                                <AccessTimeFilledIcon/>
                                Motum Xpress
                                </Typography>
                                <Typography className="card-content" variant="body1">
                                Entregando seu pedido em até 30 minutos
                                </Typography>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <Button className="card-button" size="small">Saiba mais</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid size={3}>
                        <Card className="card-homepage" sx={{ width: "290px", textAlign: "center", ml: "50px" }}>
                            <CardContent>
                                <Typography className="card-title" variant="h5" component="div">
                                Integrações
                                </Typography>
                                <Typography className="card-content" variant="body1">
                                Adicione os seus pedidos automaticamente
                                </Typography>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <Button className="card-button" size="small">Saiba mais</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Box sx={{ display:"flex", '@media (min-width: 1400px)': { display: 'none' }, justifyContent: "center" }}>
                    <Button onClick={prevItem} sx={{ marginRight: 2 }}>
                        <ArrowBackIcon />
                    </Button>

                    <Card className="card-homepage" sx={{ width: "290px", textAlign: "center"}}>
                            <CardContent>
                                <Typography className="card-title-small" variant="h5" component="div">
                                {carouselItems[currentIndex].icon}
                                {carouselItems[currentIndex].title}
                                </Typography>
                                <Typography className="card-content-small" variant="body1">
                                {carouselItems[currentIndex].content}
                                </Typography>
                                </CardContent>
                            <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                <Button className="card-button-small" size="small">Saiba mais</Button>
                            </CardActions>
                        </Card>

                    <Button onClick={nextItem} sx={{ marginLeft: 2 }}>
                        <ArrowForwardIcon />
                    </Button>
                    </Box>
            </Box>
            <Box sx={{width:"100%", backgroundImage:""}}>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={6} className="primary-section-3">
            <Typography className="titulo-3-section" variant="h2" gutterBottom>
                A Motum vai até você buscar seus pacotes!
                <Typography className="subtitulo-3-section" variant="h5" gutterBottom>
                Serviço de envio simples, rápido, seguro, sem custo extra e sem mínimo de pacote.
                <br/>
                Encontramos o entregador mais próximo do seu endereço para retirar o pacote e fazer a entrega. Simples, Rápido e Seguro!

                </Typography>
            </Typography>
            
            </Grid>
            <Grid size={6} className="second-section-3" sx={{ backgroundImage: `url(${section3})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none',}}}>
            <Grid size={12} className="primary-section-3-lg" sx={{ backgroundImage: `url(${section3})`, backgroundSize: "cover"}}>
            </Grid>
            <Grid size={12} sx={{height:"500px", backgroundColor:"white", display:"flex", alignItems:"center", width: "100%"}}>
            <Typography className="titulo-3-section-lg" variant="h2" gutterBottom>
                A Motum vai até você buscar seus pacotes!
                <Typography className="subtitulo-3-section-lg" variant="h5" gutterBottom>
                Serviço de envio simples, rápido, seguro, sem custo extra e sem mínimo de pacote.
                <br/>
                Encontramos o entregador mais próximo do seu endereço para retirar o pacote e fazer a entrega. Simples, Rápido e Seguro!

                </Typography>
            </Typography>
            
            </Grid>

            </Grid>
            
            </Box>
            
            <Box sx={{width:"100%", height:"664px", backgroundImage:""}}>
            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'none', lg:'flex'}}}>
            <Grid size={6} className="primary-section-4">
            <Typography className="titulo-3-section" variant="h2" gutterBottom>
                Minha Entrega
                <Typography className="subtitulo-3-section" variant="h5" gutterBottom>
                A Motum entrega e você acompanha o pedido em tempo real pela plataforma. 
                <br/>
                Enviamos SMS para o destinatário assim ele pode ficar sabendo quando o pacote vai chegar.

                </Typography>
                <Button className="send-now-button" sx={{ mb: "12px" }} >Localizar Minha Entrega</Button>
            </Typography>
            
            </Grid>
            <Grid size={6} className="second-section-3" sx={{ backgroundImage: `url(${section4})`, backgroundSize: "cover"}}>
            </Grid>

            </Grid>

            <Grid className="grid-master" container spacing={0} sx={{display:{xs:'flex', lg:'none'}}}>
            <Grid size={12} className="primary-section-4-lg" sx={{ backgroundImage: `url(${section4})`, backgroundSize: "cover", backgroundPosition:"bottom"}}>
            </Grid>
            <Grid size={12} sx={{height:"500px", backgroundColor:"white", display:"flex", alignItems:"center"}}>
            <Typography className="titulo-3-section-lg" variant="h2" gutterBottom>
                Minha Entrega
                <Typography className="subtitulo-3-section-lg" variant="h5" gutterBottom sx={{mb: "50px"}}>
                A Motum entrega e você acompanha o pedido em tempo real pela plataforma. 
                <br/>
                Enviamos SMS para o destinatário assim ele pode ficar sabendo quando o pacote vai chegar.

                </Typography>
                <Button className="send-now-button" sx={{ mb: "12px" }} >Localizar Minha Entrega</Button>
            </Typography>
            
            </Grid>

            </Grid>
            
            </Box>
            </React.Fragment>
    );
}