export default class HttpService {
    async _handleErrors(response) {
        if (response.ok) return response;

        const erro = await response.json();
        throw new Error(erro.error);
    }

    async get(url) {
        const resposta = await fetch(url);
        const respostaVerifiada = await this._handleErrors(resposta);
        return respostaVerifiada.json();
    }

    async post(url, dado) {
        const resposta = await fetch(url, {
            headers: { "Content-type": "application/json", "Access-Control-Allow-Origin": "*" },
            method: "POST",
            body: JSON.stringify(dado),
        });
        return this._handleErrors(resposta);
    }

    async put(url, dado) {
        const resposta = await fetch(url, {
            headers: { "Content-type": "application/json", "Access-Control-Allow-Origin": "*" },
            method: "PUT",
            body: JSON.stringify(dado),
        });
        return this._handleErrors(resposta);
    }

    async delete(url, dado) {
        const resposta = await fetch(url, {
            headers: { "Content-type": "application/json", "Access-Control-Allow-Origin": "*" },
            method: "DELETE",
            body: JSON.stringify(dado),
        });
        return this._handleErrors(resposta);
    }
}
