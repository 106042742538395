import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMousePointer,
    faClock,
    faPhone,
    faLocationDot,
    faStore,
    faClipboardList,
    faImage,
    faTruckFast,
    faPercent,
    faMoneyBills,
    faMoneyBillTransfer,
    faCheck,
    faAddressCard,
    faTicket,
    faCommentDots,
    faBagShopping
} from '@fortawesome/free-solid-svg-icons';
import Conversor from "../../../../helper/Conversor";
import OrderService from "../../../../services/OrderService";
import IfoodModal from "./IfoodModal";
import { ThreeCircles } from 'react-loader-spinner';
import Swal from 'sweetalert2';

function OrderDetails({ order, setSpinner }) {
    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [selectedCancellationOption, setSelectedCancellationOption] = useState(null);

    const orderService = useMemo(() => new OrderService(), []);

    // Cancellation Modal States
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = async () => {
        setIsModalOpen(false);
    };

    const handleOptionChange = (event) => {
        setSelectedCancellationOption(JSON.parse(event.target.value));
    };

    async function loadCancellationReasons(orderId, storeId) {
        try {
            const reasons = await orderService.getCancellationReasons(orderId, storeId);
            if (reasons.length) {
                setTimeout(() => {
                    setCancellationReasons(reasons);
                }, 1500);
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false,
            });
        }

    }

    async function requestCancellation(orderId, storeId) {
        try {
            setIsModalOpen(false);
            setSpinner(true);
            const response = await orderService.requestCancellation(orderId, storeId,
                selectedCancellationOption.cancelCodeId, selectedCancellationOption.description);
            if (response) {
                setSpinner(false);
                Swal.fire({
                    title: 'Sua solicitação de cancelamento do pedido foi enviada!',
                    text: 'O iFood analisará sua solicitação.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            setSpinner(false);
            console.error(error);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false,
            });
        }
    }

    useEffect(() => {
        if (isModalOpen && cancellationReasons.length === 0) {
            loadCancellationReasons(order._id, order.store.id);
        }
        // eslint-disable-next-line
    }, [isModalOpen]);

    const statusMap = {
        "PLACED": <strong style={{ color: "#EA1D2C" }}>Pendente</strong>,
        "CONFIRMED": <strong style={{ color: "#50A773" }}>Pedido em preparo</strong>,
        "DISPATCHED": <strong style={{ color: "#50A773" }}>Despachado</strong>,
        "CONCLUDED": <strong style={{ color: "#000" }}>Concluído</strong>,
        "CANCELLED": <strong style={{ color: "#000" }}>Cancelado</strong>,
        "READY_TO_PICKUP": <strong style={{ color: "#50A773" }}>Pronto</strong>,
    }

    const statusMapAt = {
        "PLACED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.createdAt) : ''}`,
        "CONFIRMED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.confirmedAt) : ''}`,
        "DISPATCHED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.dispatchedAt) : ''}`,
        "CONCLUDED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.concludedAt) : ''}`,
        "CANCELLED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.cancelledAt) : ''}`,
        "READY_TO_PICKUP": "Aguarde a retirada pelo cliente",
    }

    const methodMap = {
        "CASH": "Dinheiro",
        "CREDIT": "Crédito",
        "DEBIT": "Débito",
        "MEAL_VOUCHER": "Vale-refeição",
        "FOOD_VOUCHER": "Vale-alimentação",
        "GIFT_CARD": "Cartão presente",
        "DIGITAL_WALLET": "Carteira digital",
        "PIX": "PIX",
        "OTHER": "Outro"
    }

    const benefitMap = {
        "IFOOD": "O iFood pagará este valor para a sua loja",
        "MERCHANT": "Valores cobrados da sua loja"
    }

    function renderPaymentMethod(payments) {
        const chargeOnDelivery = payments.pending > 0;
        let renderText = '';
        if (chargeOnDelivery) {
            const offlineMethods = payments.methods.filter(payment => payment.type === "OFFLINE");
            renderText = `Cobrar do cliente - ${methodMap[offlineMethods[0].method]} - ${offlineMethods[0].method !== "CASH" ? offlineMethods[0].card.brand : 'Dinheiro'} via loja`
        } else {
            const onlineMethods = payments.methods.filter(payment => payment.type === "ONLINE");
            renderText = `Pago via iFood - ${methodMap[onlineMethods[0].method]} - ${onlineMethods[0].card.brand}`
        }

        return renderText;
    }

    function calculateTimeInterval(date) {
        const oldDate = new Date(date);
        const actualDate = new Date();
        const millesecondsDiference = actualDate - oldDate;
        
        const minutes = Math.floor(millesecondsDiference / 1000 / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
    
        if (minutes < 60) {
            return `Há ${minutes} minuto(s)`;
        } else if (hours < 24) {
            return `Há ${hours} hora(s)`;
        } else {
            return `Há ${days} dia(s)`;
        }
    }

    function formatHoursAndMinutes(dateAt) {
        const date = new Date(dateAt);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function formatarDataISO(dataISO) {
        const data = new Date(dataISO);
        const dia = String(data.getUTCDate()).padStart(2, '0');
        const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
        const ano = data.getUTCFullYear();
        let horas = data.getUTCHours();
        const minutos = String(data.getUTCMinutes()).padStart(2, '0');
        const dataFormatada = `${dia}/${mes}/${ano} às ${horas}h${minutos}`;
        return dataFormatada;
    }

    function is0800Number(phoneNumber) {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        return cleanedNumber.startsWith('0800');
    }

    return (
        <div className="ifood-order-details">
            {order ? (
                <div className="order-details-selected-order">
                    <div className="requester-name">{order.requester.name}</div>
                    <div className="first-header">
                        <span className="display-id">Pedido #{order.orderDetails.ifoodDetails.displayId}</span>
                        <span>●</span>
                        <span className="created-at">Feito às <b>{convertDateToHoursAndMinutes(order.orderDetails.ifoodDetails.createdAt)}</b></span>
                        <span>●</span>
                        <span className="localizer-number">Localizador do pedido <b>{order.orderDetails.ifoodDetails.localizer}</b></span>
                    </div>
                    <div className="second-header">
                        <div className="header-box">
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: 5, height: 10 }} />
                            {order.orderDetails.ifoodDetails.orderTiming === "SCHEDULED" ? (
                                <span>Entregar entre {formatarDataISO(order.orderDetails.ifoodDetails.schedule.deliveryDateTimeStart)} e {formatarDataISO(order.orderDetails.ifoodDetails.schedule.deliveryDateTimeEnd)}</span>
                            ) : (
                                <span>{order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? `Entrega prevista: ${formatHoursAndMinutes(order.orderDetails.ifoodDetails.deliveryDateTime)}` : `Retirada prevista: ${formatHoursAndMinutes(order.orderDetails.ifoodDetails.takeout.takeoutDateTime)}`} </span>
                            )}
                        </div>
                        <div className="header-box">
                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5, height: 10 }} />
                            {order.requester.cellNumber}
                            {is0800Number(order.requester.cellNumber) ? " ID: " + order.orderDetails.ifoodDetails.localizer : ""}
                        </div>
                    </div>
                    <div className="status-container">
                        <h6>Status do pedido</h6>
                        <div className="status-text">
                            {statusMap[order.orderDetails.ifoodDetails.status]}
                        </div>
                        <h6>{statusMapAt[order.orderDetails.ifoodDetails.status]}</h6>
                    </div>
                    <div className="customer-address-container">
                        <div className="address">
                            <div className="location-icon">
                                <FontAwesomeIcon icon={faLocationDot} style={{ height: 10 }} />
                            </div>

                            {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (
                                <span>
                                    {order.requester.address.street} ,
                                    {' ' + order.requester.address.number}  -
                                    {' ' + order.requester.address.neighborhood}  -
                                    {order.requester.address.city} ●
                                    {' ' + order.requester.cellNumber}
                                    {order.requester.address.complement} ●
                                    {' ' + order.requester.address.state}
                                </span>) : (<span>Cliente irá retirar no restaurante. Não é necessário enviar este pedido</span>)}

                        </div>
                        <div className="delivery-type">
                            <FontAwesomeIcon icon={order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? faStore : faBagShopping} style={{ height: 10, marginRight: 5 }} />
                            {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (`${order.orderDetails.ifoodDetails.delivery.deliveredBy === "MERCHANT" ? "Entrega Própria" : "Entrega iFood"}`) : "Retirada"}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="items-header">
                            <FontAwesomeIcon icon={faClipboardList} style={{ height: 15, marginRight: 5 }} />
                            Itens do pedido
                        </div>
                        <div className="items-map">
                            {order.orderDetails.ifoodDetails.items.map((item) => (
                                <div className="item-content" key={item.index}>
                                    <div className="item-details">
                                        <div className="main-item">
                                            <div className="item-image"><FontAwesomeIcon icon={faImage} style={{ height: 18 }} /></div>
                                            <div className="item-quantity">{item.quantity}x</div>
                                            <div className="item-name">{item.name}</div>
                                        </div>
                                        <div className="item-options">
                                            {item.options && item.options.map((option) => (
                                                <div className="options-container">
                                                    <div className="option-info">
                                                        <div className="vertical-line"></div>
                                                        <div className="option-quantity" style={{ marginRight: 10 }}>{option.quantity}x</div>
                                                        <div className="option-name">{option.name}</div>
                                                    </div>
                                                    <div className="option-price">{Conversor.converterStringReal(option.price)}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {item.observations ? (
                                            <div className="item-observations">
                                                <FontAwesomeIcon icon={faCommentDots} height={10} style={{ marginRight: 8 }} />
                                                {item.observations}
                                            </div>
                                        ) : (null)}
                                    </div>
                                    <div className="item-price" style={{ marginTop: 15 }}>{Conversor.converterStringReal(item.totalPrice)}</div>
                                </div>
                            ))};
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faTruckFast} height={10} style={{ marginRight: 10 }} />
                                Taxa de entrega
                            </div>
                            {/* TODO: depois trocar para o frete do EDV */}
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.ifoodDetails.total.deliveryFee)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faPercent} height={10} style={{ marginRight: 10 }} />
                                Taxa de Serviço
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.ifoodDetails.total.additionalFees)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faMoneyBills} height={10} style={{ marginRight: 10 }} />
                                Subtotal
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.ifoodDetails.total.subTotal)}</div>
                        </div>

                        {order.orderDetails.ifoodDetails.benefits ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.benefits ? (
                            <div className="price-container">
                                <div className="price-info" style={{ display: 'inline-flex' }}>
                                    <FontAwesomeIcon icon={faTicket} height={10} style={{ marginRight: 10 }} />
                                    <div className="benefit-info">
                                        <div className="show-benefits">
                                            {order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).description}
                                        </div>
                                        <div className="benefit-subtitle">
                                            <span>{benefitMap[order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).name]}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="price-total">-{Conversor.converterStringReal(order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).value)}</div>
                            </div>
                        ) : (null)}

                        <div className="line"></div>
                        <div className="price-container">
                            <div className="payment-methods-info">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} height={10} style={{ marginRight: 10 }} />
                                <div className="payment-info">
                                    <div className="payment-title" style={{ marginBottom: 5 }}>{renderPaymentMethod(order.orderDetails.ifoodDetails.payments)}</div>
                                    <div className="payment-subtitle">{order.orderDetails.ifoodDetails.payments.pending > 0 ? 'O entregador deve cobrar este valor no ato da entrega' : 'O iFood já recebeu este valor e vamos repassar a sua loja'}</div>
                                </div>
                            </div>
                            <div className="price-total">{order.orderDetails.ifoodDetails.payments.pending > 0 ? Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.pending) : '-' + Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.prepaid)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faCheck} height={10} style={{ marginRight: 10 }} />
                                {order.orderDetails.ifoodDetails.payments.pending === 0 ? (
                                    "Pago via iFood, não precisa cobrar na entrega"
                                ) : (renderPaymentMethod(order.orderDetails.ifoodDetails.payments))}
                            </div>
                        </div>
                        
                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (
                            <div className="price-container">Valor a receber em dinheiro: {Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.methods[0].cash.changeFor)}</div>
                        ) : ("")}

                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (
                            <div className="price-container">Valor para levar de troco: {Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.methods[0].cash.changeFor - order.orderDetails.ifoodDetails.payments.methods[0].value)}</div>
                        ) : ("")}

                        <div className="line"></div>
                        {order.requester.cpf ? (
                            <div className="pending-info-container">
                                <FontAwesomeIcon icon={faAddressCard} height={10} style={{ marginRight: 10 }} />
                                Incluir CPF na nota fiscal {order.requester.cpf}
                            </div>
                        ) : ("")}



                    </div>

                    {order.orderDetails.ifoodDetails.status === "PLACED" ? (
                        <div className="cancel-order-container">
                            <button type="button" className="cancel-order-button" onClick={handleOpenModal}>
                                Cancelar pedido
                            </button>
                        </div>
                    ) : ("")}

                    {/* Modal de Cancelamento */}
                    <IfoodModal show={isModalOpen} onClose={handleCloseModal}>
                        <div className="cancellation-ifood-modal-title">Cancelar pedido</div>
                        <div className="cancellation-ifood-modal-subtitle">
                            Cancelar muitos pedidos pode afetar o desempenho da sua loja no iFood. Assim que possível,
                            ajuste sua operação para não cancelar novos pedidos pelo mesmo motivo.
                        </div>
                        <div className="cancellation-ifood-modal-danger">
                            ATENÇÃO: Muitos cancelamentos pela falta de confirmação podem fechar o seu restaurante na plataforma
                        </div>
                        <div className="cancellation-ifood-modal-subtitle">Selecione o motivo pelo qual você não pode aceitar esse pedido:</div>
                        {cancellationReasons.length ? (
                            <div className="cancellation-ifood-radio-select">
                                {cancellationReasons.map(option => (
                                    <div className="map-option" key={option.cancelCodeId}>
                                        <input
                                            type="radio"
                                            name={option.cancelCodeId}
                                            id={option.cancelCodeId}
                                            value={JSON.stringify(option)}
                                            checked={selectedCancellationOption && (selectedCancellationOption.cancelCodeId === option.cancelCodeId)}
                                            onChange={handleOptionChange}
                                        />
                                        <label htmlFor={option.cancelCodeId}>{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="loading-cancellations-reasons-spinner">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        )}

                        <div className="cancellation-ifood-modal-actions">
                            <button className="modal-back-action" onClick={handleCloseModal}>Voltar</button>
                            <button className="modal-cancel-action" onClick={async () => await requestCancellation(order._id, order.store.id)} disabled={!selectedCancellationOption}
                                style={selectedCancellationOption ? { color: '#fff', backgroundColor: '#EA1D2C', cursor: 'pointer' } :
                                    { color: '#DCDCDC', backgroundColor: 'transparent', cursor: 'not-allowed' }}>Cancelar pedido</button>
                        </div>
                    </IfoodModal>
                </div>
            ) : (
                <div className="order-details-not-order">
                    <h1>As informações do seu pedido apareceram aqui!</h1>
                    <h6>Selecione um pedido <FontAwesomeIcon icon={faMousePointer} height={15} style={{ marginLeft: 5 }} /></h6>
                </div>
            )}
        </div>
    );
}

const convertDateToHoursAndMinutes = (deliveryDateTime) => {
    const date = new Date(deliveryDateTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export default OrderDetails;