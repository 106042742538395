import { Component } from 'react';
import './style.scss';

class Select extends Component {
  changeValue(event) {
    this.props.setDisableChange(event.target.value === 'dinheiro' ? false : true);
  }

  render() {
    return (
      <select
        className={`select ${this.props.labelAnimation}`}
        name={this.props.name}
        id={this.props.name}
        onChange={this.changeValue.bind(this)}
        data-forma-pagamento
        required={this.props.required === "" ? false : true}
      >
        <option className="select__label" value="">
          {this.props.content}
        </option>
        {this.props.options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.name}
          </option>
        ))}
      </select>
    );
  }
}

export default Select;
