import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faChartSimple, faCheckSquare, faHeadset, faNetworkWired, faSquareXmark, faUserCheck, faUserPen, faWallet } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import AdminService from '../../../services/AdminService';
import Conversor from '../../../helper/Conversor';
import PendingUsers from '../PendingUsers/PendingUsers';
import Integrations from '../Integrations/Integrations';
import ManageUsers from '../ManageUsers/ManageUsers';
import ManageWallet from '../ManageWallet/ManageWallet';
import RegisterSupport from '../RegisterSupport/RegisterSupport';
import Reports from '../Reports/Reports';
import Select from 'react-select';
import Swal from 'sweetalert2';

export default function Admin(props) {
    const [logs, setLogs] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [selectedPage, setSelectedPage] = useState("MENU");
    const adminService = useMemo(() => new AdminService(), []);

    async function loadLogs() {
        if (loadingLogs) {
            return;
        }
        setLoadingLogs(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getLogs();
            setLogs(response);
            props.setSpinner(false)
        }, 2000);
    }

    async function loadUsers() {
        if (loadingUsers) {
            return;
        }
        setLoadingUsers(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getUsers();
            setUsers(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleBackMenu() {
        setSelectedPage("MENU");
    }

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingLogs]);

    function handleChangeUser(userOption) {
        props.setSpinner(true);
        setSelectedUser(users[users.findIndex(user => user._id === userOption.value)]);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    function handlePage(page) {
        if((page === "MANAGE_USERS" || page === "MANAGE_WALLET" || page === "REGISTER_SUPPORT") && !selectedUser) {
            Swal.fire({
                icon: 'error',
                title: 'Selecione um usuário',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }
        setSelectedPage(page)
    }

    const usersOptions = users.map(({ _id, name }) => ({ value: _id, label: name }));

    return (
        <div className="content-container admin-container">
            {selectedPage === "MENU" ? (
                <div className="select-user-container">
                    <Select
                        options={usersOptions}
                        onChange={handleChangeUser}
                        value={selectedUser ? usersOptions.find((user) => user.value === selectedUser._id) : null}
                        placeholder="Selecione um usuário"
                        className="select-user"
                    />
                </div>
            ) : (null)}
            {selectedPage === "APROVE_USERS" ? (<PendingUsers handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "INTEGRATIONS" ? (<Integrations handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "MANAGE_USERS" ? (<ManageUsers selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "MANAGE_WALLET" ? (<ManageWallet selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "REGISTER_SUPPORT" ? (<RegisterSupport selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "REPORTS" ? (<Reports handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} />) : (null)}
            {selectedPage === "MENU" ? (
                <div className="menu">

                    <div className="support-actions-container">
                        <div className="action-box" onClick={() => handlePage("APROVE_USERS")}>
                            <FontAwesomeIcon className="action-icon" icon={faUserCheck} />
                            <div className="action-label">Aprovar usuários</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("INTEGRATIONS")}>
                            <FontAwesomeIcon className="action-icon" icon={faNetworkWired} />
                            <div className="action-label">Integrações</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("MANAGE_USERS")}>
                            <FontAwesomeIcon className="action-icon" icon={faUserPen} />
                            <div className="action-label">Alterar cadastro</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("MANAGE_WALLET")}>
                            <FontAwesomeIcon className="action-icon" icon={faWallet} />
                            <div className="action-label">Carteiras</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("REGISTER_SUPPORT")}>
                            <FontAwesomeIcon className="action-icon" icon={faHeadset} />
                            <div className="action-label">Cadastrar Suporte</div>
                        </div>
                        <div className="action-box" onClick={() => handlePage("REPORTS")}>
                            <FontAwesomeIcon className="action-icon" icon={faChartSimple} />
                            <div className="action-label">Relatórios</div>
                        </div>
                    </div>
                    <div className="activity-logs-container">
                        <div className="activity-logs-title">Atividades no Sistema</div>
                        <div className="activity-logs-list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Tipo</th>
                                        <th>Usuário</th>
                                        <th>Descrição</th>
                                        <th>Status</th>
                                        <th>Recurso</th>
                                        <th>Localização</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.length > 0 && logs.map((log) => (
                                        <tr>
                                            <td>{Conversor.converterData(log.timestamp)}</td>
                                            <td>{log.activityType}</td>
                                            <td>{log.userName}</td>
                                            <td>{log.description}</td>
                                            <td className='status-label'>{log.status === "Success" ? <FontAwesomeIcon icon={faCheckSquare} color='green' /> : <FontAwesomeIcon icon={faSquareXmark} color='red' />}</td>
                                            <td>{log.resourceId}</td>
                                            <td>{log.ipAddress}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (null)}

        </div>
    )
}