import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { ThreeCircles } from 'react-loader-spinner';
import Swal from "sweetalert2";
import '../scss/config.scss';

const ConfigMenu = ({ onSubmitInterruptionForm, ifoodService, storeContext }) => {
    const [listInterruptionsVisible, setListInterruptionsVisible] = useState(true);
    const [createInterruptionsVisible, setCreateInterruptionsVisible] = useState(false);

    const [interruptions, setInterruptions] = useState([]);
    const [loadingInterruptions, setLoadingInterruptions] = useState(false);
    const isLoading = useRef(false);

    const handleCreateInterruption = () => {
        setListInterruptionsVisible(false);
        setCreateInterruptionsVisible(true);
    }

    const handleBack = () => {
        setListInterruptionsVisible(true);
        setCreateInterruptionsVisible(false);
    }

    function converterDataHora(dataHora) {
        const data = new Date(dataHora);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        return `${dia}/${mes}/${ano} às ${horas}h${minutos}`;
    }

    const deleteInterruption = async (interruptionId) => {
        try {
            const response = await ifoodService.deleteMerchantInterruption(storeContext._id, interruptionId);
            if (response.ok) {
                setLoadingInterruptions(false);
                Swal.fire({
                    title: 'Interrupção removida com sucesso!',
                    html: 'Confira suas interrupções na aba Configurações -> Interrupções.<br> As alterações podem demorar alguns minutos.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false
                });
            }
        } catch (error) {
            console.error(error.message);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            isLoading.current = false;
        }
    }

    const loadInterruptions = useCallback(async () => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        // Limpando todos os pedidos
        setInterruptions([]);

        setLoadingInterruptions(true);
        try {
            const data = await ifoodService.getMerchantInterruptions(storeContext._id);
            setTimeout(() => {
                setInterruptions(data);
            }, 2000);
        } finally {
            isLoading.current = false;
        }
    }, [ifoodService, storeContext]);

    useEffect(() => {
        if (!isLoading.current && !loadingInterruptions) {
            loadInterruptions();
        }
    }, [loadingInterruptions, loadInterruptions]);

    return (
        <div className="main-config">
            <div className="config-title">Configurações</div>
            <div className="config-container">
                <div className="config-menu">
                    <div className="menu-item" style={true ? { color: "#EA1D2C" } : { color: "#000" }}>Interrupções</div>
                </div>
                <div className="vertical-config-line"></div>

                {listInterruptionsVisible ? (
                    <div className="config-content">
                        <div className="header-config">
                            <div className="title">Minhas Interrupções</div>
                            <div className="header-actions-buttons">
                                {createInterruptionsVisible ? (<button type="button" className="back-button" onClick={handleBack}>Voltar</button>) : (null)}
                                {listInterruptionsVisible ? (<button type="button" className="create-button" onClick={handleCreateInterruption}>Criar</button>) : (null)}
                            </div>
                        </div>

                        {isLoading.current ? (
                            <div className="loading-content">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        ) : (
                            <div className="content">
                                {interruptions.length && loadingInterruptions ? (
                                    <table className="interruptions-list">
                                        <thead>
                                            <tr>
                                                <th>Descrição</th>
                                                <th>Início</th>
                                                <th>Término</th>
                                                <th className="delete-action">Remover</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {interruptions.map((interruption) => (
                                                <tr key={interruption.id}>
                                                    <td>{interruption.description}</td>
                                                    <td>{converterDataHora(interruption.start)}</td>
                                                    <td>{converterDataHora(interruption.end)}</td>
                                                    <td className="delete-action"><FontAwesomeIcon onClick={() => deleteInterruption(interruption.id)} icon={faXmark} color="#EA1D2C" style={{cursor: "pointer", height: 16}} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="not-interruptions">Parece que não há nenhuma interrupção por enquanto!</div>
                                )}
                            </div>

                        )}
                    </div>
                ) : (null)}

                {createInterruptionsVisible ? (
                    <div className="config-content">
                        <div className="header-config">
                            <div className="title">Criar Interrupções</div>
                            <div className="header-actions-buttons">
                                {createInterruptionsVisible ? (<button type="button" className="back-button" onClick={handleBack}>Voltar</button>) : (null)}
                                {listInterruptionsVisible ? (<button type="button" className="create-button" onClick={handleCreateInterruption}>Criar</button>) : (null)}
                            </div>
                        </div>
                        <div className="content">
                            <form action="post" className="create-interruption-form" onSubmit={onSubmitInterruptionForm}>
                                <div className="ifood-form-control">
                                    <label htmlFor="description" className="form-label">Descrição</label>
                                    <textarea name="description" id="description" placeholder="Descreva o porque da interrupção."></textarea>
                                </div>
                                <div className="interruption-dates">
                                    <div className="ifood-form-control">
                                        <label htmlFor="start" className="form-label">Início da Interrupção</label>
                                        <input type="datetime-local" name="start" id="start" />
                                    </div>
                                    <div className="ifood-form-control">
                                        <label htmlFor="end" className="form-label">Fim da Interrupção</label>
                                        <input type="datetime-local" name="end" id="end" />
                                    </div>
                                </div>
                                <div className="ifood-form-actions">
                                    <button type="submit">Criar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (null)}
            </div>
        </div>
    );
}

export default ConfigMenu;