import HttpService from "./HttpService.js";

export default class AdminService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/admin`;
        this._urlUser = `${process.env.REACT_APP_DELIVERIES_API}/users`;
        this._urlWallet = `${process.env.REACT_APP_DELIVERIES_API}/wallet`;
        this._urlDeliveryman = `${process.env.REACT_APP_DELIVERIES_API}/deliveryman`;
    }

    async getLogs() {
        const response = await this._http.get(this._urlBase+`/logs`);
        return response;
    }

    async getUsers() {
        const response = await this._http.get(this._urlBase+"/users");
        return response;
    }

    async updateUser(userId, userData) {
        const response = await this._http.put(this._urlUser+`/id/${userId}`, userData);
        return response;
    }

    async getWallets() {
        const response = await this._http.get(this._urlBase+"/list_users_with_wallets");
        return response;
    }

    async getSupports() {
        const response = await this._http.get(this._urlBase+`/get_supports`);
        return response;
    }

    async createSupport(support) {
        const response = await this._http.post(this._urlBase+`/create_support`, support);
        return response;
    }

    async addBalance(walletId, value) {
        const response = await this._http.post(this._urlWallet+`/${walletId}/add_transaction`, {
            "type": "CREDIT",
            "status": "APROVED",
            "amount": {
              "value": value,
              "currency": "BRL"
            },
            "description": "Depósito realizado"
        });
        return response;
    }

    async removeBalance(walletId, value) {
        const response = await this._http.post(this._urlWallet+`/${walletId}/add_transaction`, {
            "type": "DEBIT",
            "status": "APROVED",
            "amount": {
              "value": value,
              "currency": "BRL"
            },
            "description": "Saldo removido"
        });
        return response;
    }

    async getPendingUsers() {
        const response = await this._http.get(this._urlUser+'/get_pending_users');
        return response;
    }

    async updateUserStatus(id, status, email) {
        const response = await this._http.put(this._urlUser+`/updateStatus/${id}`, {
            status: status,
            email: email
        });
        return response;
    }

    async updateDeliveryManStatus(id, status) {
        const response = await this._http.put(this._urlDeliveryman+`/${id}`, {
            status: status
        });
        return response;
    }
}
