import React, { useContext, useEffect, useState } from 'react';
import LoginService from '../../../services/LoginService';
import { Navigate } from 'react-router';
import { UserContext } from '../../context/UserContext';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import motumHomeMap from "../../../assets/img/motum-home-map.png";
import motumLogo from "../../../assets/img/motum-logo.png"
import './style.scss';


const Login = (props) => {
  const { user, selectedStore, updateUser, stayConnected, updateStayConnected } = useContext(UserContext);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const changeStayConnected = (event) => {
    updateStayConnected(event.target.checked);
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const handlePassword = (event) => {
    setPassword(event.target.value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    const loginService = new LoginService();
    props.setSpinner(true);
    setTimeout(async () => {
      try {
        const response = await loginService.sendLoginRequest(encodedCredentials);
        props.setSpinner(false);
        Swal.fire({
          title: 'Login bem-sucedido!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
          didClose: () => {
            updateUser(response);
            setRedirect(true);
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Ocorreu um erro ao realizar o login!',
          text: error,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          didClose: () => {
            props.setSpinner(false);
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    document.title = `Login | Motum`;
  }, [user]);

  if ((redirect && user && selectedStore) || (user && selectedStore)) {
    if (user.type === "ADMIN") {
      return <Navigate to={'/followup'} />
    } else {
      return <Navigate to={'/request'} />;
    }
  }

  return (
    <React.Fragment>
      <div className="main-login-container">
        <div className="left-login-image-container">
          <div className="login-image-info">
            <h1>De Onde Você Estiver.</h1>
            <h1>Para Qualquer Lugar de Sergipe.</h1>
            <p>Mais rápido e mais acessível!</p>
          </div>
          <div className="login-image">
            <img src={motumHomeMap} alt="Mapa Motum" />
          </div>
        </div>
        <div className="main-login-form">
          <form onSubmit={onSubmit} data-form>
            <div className="form-login-logo">
              <img src={motumLogo} alt="Motum Logo" />
            </div>
            <div className="form-login-title"><h1>Entre na sua conta</h1></div>
            <div className="login-form-control">
              <label htmlFor="email">E-mail</label>
              <input 
                type="email" 
                name="email" 
                id="email" 
                placeholder="Digite seu e-mail" 
                onChange={handleEmail}
              />
            </div>
            <div className="login-form-control">
              <label htmlFor="password">Senha</label>
              <input 
                type="password" 
                name="password" 
                id="password" 
                placeholder="Digite sua senha" 
                onChange={handlePassword}
              />
            </div>
            <div className="login-form-check">
              <div className="logged-check">
                <input type="checkbox" name="logged" id="logged" checked={stayConnected} onChange={changeStayConnected} />
                <span>Matenha-me conectado</span>
              </div>
              {/* 
              OBSOLETO
              <div className="select-model">
                <label htmlFor="">Fazer login como</label>
                <Select className='login-select' defaultValue={selectOptions[1]} options={selectOptions} onChange={handleModel} />
              </div> */}
            </div>
            <button className="login-submit-btn" data-button-submit>Entrar</button>
            <div className="main-footer-login">
              <span className="footer-login-text">Ainda não tem uma conta?</span>
              <span className="footer-login-link"><Link to="/register">Cadastre-se</Link></span>
            </div>
          </form>
        </div>
      </div>

    </React.Fragment>
  );
};

export default Login;
