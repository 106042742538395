import React from 'react';
import InputMask from 'react-input-mask';

function StoreForm(props) {

    const formData = props.formStoreData;
    const handleChange = props.handleChangeStore;

    return (
        <div className='store-form'>
            <div className='form-title'>Dados da Loja</div>
            <div className='form-subtitle'>Identificação</div>
            <label className='form-label mb2'>
                <div className='label-text'>Nome da Loja</div>
                <input className="form-control" placeholder='Digite o nome da loja' type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
            </label>
            <label className='form-label mb1'>
                <div className='label-text'>CNPJ</div>
                <InputMask
                    className="form-control"
                    placeholder='Digite seu CPNJ'
                    type="text"
                    name="cnpj"
                    mask="99.999.999/9999-99" // Máscara para CNPJ
                    value={formData.cnpj}
                    onChange={handleChange}
                    required
                />
            </label>
            <label className='form-label mb1'>
                <div className='label-text'>Telefone</div>
                <InputMask
                    className="form-control"
                    placeholder='Digite o telefone da loja'
                    type="text"
                    name="cellNumber"
                    mask="(99) 99999-9999" // Máscara para Telefone
                    value={formData.cellNumber}
                    onChange={handleChange}
                    required
                />
            </label>
            <div className='form-subtitle'>Endereço</div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>CEP</div>
                    <InputMask
                        className="form-control"
                        placeholder='Digite o CEP da loja'
                        type="text"
                        name="cep"
                        mask="99999-999" // Máscara para CEP
                        value={formData.cep}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Rua</div>
                    <input className="form-control" placeholder='Digite a rua da loja' type="text" name="street" value={formData.street} onChange={handleChange} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Nº</div>
                    <input className="form-control" placeholder='Digite o nº da loja' type="number" name="address_number" value={formData.address_number} onChange={handleChange} required />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Bairro</div>
                    <input className="form-control" placeholder='Digite o bairro da loja' type="text" name="neighborhood" value={formData.neighborhood} onChange={handleChange} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Complemento</div>
                    <input className="form-control" placeholder='Digite o complemento da loja' type="text" name="complement" value={formData.complement} onChange={handleChange} required />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Cidade</div>
                    <input className="form-control" placeholder='Digite a cidade da loja' type="text" name="city" value={formData.city} onChange={handleChange} />
                </label>
            </div>

            <label className="form-label mb1">
                <div className='label-text'>ID's da loja no IFood</div>
                <textarea className='form-control id-ifood-input' name="" id="" cols={20} rows={20} placeholder="Cole cada ID IFood separado por ',' (vírgula)."></textarea>
            </label>
        </div>
    );
}

export default StoreForm;
