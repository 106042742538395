import { Component } from "react";
import "./style.scss";

class TextArea extends Component {
    render() {
        return (
            <label className={`text-area ${this.props.labelAnimation}`} htmlFor={this.props.name}>
                <textarea className="text-area__input" id={this.props.name} name={this.props.name} rows="1" />
                <p className="text-area__label">{this.props.content}<span className="field_optional">{"(opicional)"}</span></p>
            </label>
        );
    }
}

export default TextArea;
