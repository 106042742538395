import boxOne from "../../../../assets/img/services-box-one.png";
import boxTwo from "../../../../assets/img/services-box-two.png";
import servicesApp from "../../../../assets/img/services-app.png";
import playStore from "../../../../assets/img/play-store.svg";
import apple from "../../../../assets/img/apple.svg";

export default function Services() {
    return (
        <div className="services-container">
            <h1>Nossos Serviços</h1>

            <div className="services-box-container">
                <div className="box">
                    <div className="box-title">Envie sua encomenda</div>
                    <img src={boxOne} className="box-image" alt="Envie sua encomenda" />
                    <div className="box-description">
                        Envie sua encomenda de qualquer lugar de Sergipe.
                    </div>
                    <button type="button" className="box-button">Enviar agora</button>
                </div>
                <div className="box">
                <div className="box-title">Receba encomendas</div>
                    <img src={boxTwo} className="box-image" alt="Envie sua encomenda" />
                    <div className="box-description">
                        Receba sua encomenda com segurança e rapidez.
                    </div>
                    <button type="button" className="box-button">Ler mais</button>
                </div>
            </div>

            <div className="services-app-container">
                <img src={servicesApp} alt="Baixe o app" />
                <div className="app-description">
                    <div className="app-description-title">
                        Acompanhe sua encomenda em tempo real
                    </div>
                    <div className="app-description-subtitle">
                        Através do nosso app você pode acompanhar a entrega da sua encomenda em tempo real.
                    </div>
                    <div className="app-buttons">
                        <button type="button"><img src={apple} className="button-icon" alt="Apple"/>App Store</button>
                        <button type="button"><img src={playStore} className="button-icon" alt="Google Play"/> Google Play</button>
                    </div>
                </div>
            </div>
        </div>
    );
}