import ListaCaracteres from "./ListaCatacteres.js";

export default class CaracteresEspeciais {
    static remover(texto) {
        const charMap = ListaCaracteres.getLista();
        var textoFormatado = texto.replace(/\W/gi, function (char) {
            return charMap[char] || " ";
        });
        return textoFormatado;
    }
}
