import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { ThreeCircles } from 'react-loader-spinner';
import Swal from "sweetalert2";
import '../scss/config.scss';
import '../scss/handshake.scss';

const HandShake = ({ ifoodService, storeContext }) => {
    const [changeRequests, setChangeRequests] = useState([]);
    const [cancellationRequests, setCancellationRequests] = useState([]);
    const [loadingChangeRequests, setLoadingChangeRequests] = useState(false);
    const [loadingCancellationRequests, setLoadingCancellationRequests] = useState(false);
    const [menuActive, setMenuActive] = useState("CHANGES");
    const isLoadingChanges = useRef(false);
    const isLoadingCancellations = useRef(false);

    const loadCancellationRequests = useCallback(async () => {
        if (isLoadingCancellations.current) {
            return;
        }
        isLoadingCancellations.current = true;

        setCancellationRequests([]);

        setLoadingCancellationRequests(true);
        try {
            const data = await ifoodService.getHandShakeDisputes();
            setTimeout(() => {
                setCancellationRequests(data);
            }, 2000);
        } finally {
            isLoadingCancellations.current = false;
        }
    }, [ifoodService]);

    useEffect(() => {
        if (!isLoadingCancellations.current && !loadingCancellationRequests) {
            loadCancellationRequests();
        }
    }, [loadingCancellationRequests, loadCancellationRequests]);

    const loadChangeRequests = useCallback(async () => {
        if (isLoadingChanges.current) {
            return;
        }
        isLoadingChanges.current = true;

        setChangeRequests([]);

        setLoadingChangeRequests(true);
        try {
            const data = await ifoodService.getDeliveryAddressChangeRequests();
            setTimeout(() => {
                setChangeRequests(data);
            }, 2000);
        } finally {
            isLoadingChanges.current = false;
        }
    }, [ifoodService]);

    useEffect(() => {
        if (!isLoadingChanges.current && !loadingChangeRequests) {
            loadChangeRequests();
        }
    }, [loadingChangeRequests, loadChangeRequests]);

    const acceptHandShakeDispute = async (disputeId) => {
        try {
            Swal.fire({
                title: 'Tem certeza que deseja aceitar essa solicitação?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim, aceitar!',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#EA1D2C',
                cancelButtonColor: '#E2E6EA',
            }).then(async (result) => {
                if(result.isConfirmed) {
                    const response = await ifoodService.acceptHandShakeDispute(storeContext._id, disputeId);
                    if (response.ok) {
                        setLoadingCancellationRequests(false);
                        Swal.fire({
                            title: 'Solicitação aceita com sucesso!',
                            html: 'Em alguns instantes o pedido será cancelado na plataforma.',
                            icon: 'success',
                            timer: 4000,
                            showConfirmButton: false
                        });
                    }
                }
            })
        } catch (error) {
            console.error(error.message);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            isLoadingCancellations.current = false;
        }
    }

    const rejectHandShakeDispute = async (disputeId) => {
        try {
            Swal.fire({
                title: 'Tem certeza que deseja rejeitar essa solicitação?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim, rejeitar!',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#EA1D2C',
                cancelButtonColor: '#E2E6EA',
            }).then(async (result) => {
                if(result.isConfirmed) {
                    const response = await ifoodService.rejectHandShakeDispute(storeContext._id, disputeId);
                    if (response.ok) {
                        setLoadingChangeRequests(false);
                        Swal.fire({
                            title: 'Solicitação rejeitada com sucesso!',
                            html: 'O cliente será informado da sua rejeição.',
                            icon: 'success',
                            timer: 4000,
                            showConfirmButton: false
                        });
                    }
                }
            })
        } catch (error) {
            console.error(error.message);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            isLoadingCancellations.current = false;
        }
    }

    const acceptDeliveryAddressChangeRequest = async (orderId) => {
        try {
            Swal.fire({
                title: 'Tem certeza que deseja aceitar essa solicitação?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim, aceitar!',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#EA1D2C',
                cancelButtonColor: '#E2E6EA',
            }).then(async (result) => {
                if(result.isConfirmed) {
                    const response = await ifoodService.acceptDeliveryAddressChangeRequest(storeContext._id, orderId);
                    if (response.ok) {
                        setLoadingChangeRequests(false);
                        Swal.fire({
                            title: 'Solicitação aceita com sucesso!',
                            html: 'Em alguns instantes o endereço será modificado na plataforma.',
                            icon: 'success',
                            timer: 4000,
                            showConfirmButton: false
                        });
                    }
                }
            })
        } catch (error) {
            console.error(error.message);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            isLoadingChanges.current = false;
        }
    }

    const rejectDeliveryAddressChangeRequest = async (orderId) => {
        try {
            Swal.fire({
                title: 'Tem certeza que deseja rejeitar essa solicitação?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim, rejeitar!',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#EA1D2C',
                cancelButtonColor: '#E2E6EA',
            }).then(async (result) => {
                if(result.isConfirmed) {
                    const response = await ifoodService.rejectDeliveryAddressChangeRequest(storeContext._id, orderId);
                    if (response.ok) {
                        setLoadingChangeRequests(false);
                        Swal.fire({
                            title: 'Solicitação rejeitada com sucesso!',
                            html: 'O cliente será informado da sua rejeição.',
                            icon: 'success',
                            timer: 4000,
                            showConfirmButton: false
                        });
                    }
                }
            })
        } catch (error) {
            console.error(error.message);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            isLoadingChanges.current = false;
        }
    }

    function formatAddress(address) {
        const {
            streetName,
            streetNumber,
            complement,
            reference,
            neighborhood,
            city,
            state,
            country
        } = address;
    
        // Montar a string do endereço
        let fullAddress = `${streetName || ''}, ${streetNumber || ''}`;
        
        if (complement) fullAddress += `, ${complement}`;
        if (neighborhood) fullAddress += `, ${neighborhood}`;
        if (city) fullAddress += `, ${city}`;
        if (state) fullAddress += ` - ${state}`;
        if (country) fullAddress += `, ${country}`;
        if (reference) fullAddress += ` (Referência: ${reference})`;
    
        return fullAddress.trim();
    }

    return (
        <div className="main-config">
            <div className="config-title">Negociações</div>
            <div className="config-container">
                <div className="config-menu">
                    <div className="menu-item" onClick={() => setMenuActive("CHANGES")} style={menuActive === "CHANGES" ? { color: "#EA1D2C" } : { color: "#000" }}>Alterações {changeRequests.length > 0 ? (<div className="config-menu-count">{changeRequests.length}</div>) : (null)}</div>
                    <div className="menu-item" onClick={() => setMenuActive("CANCELLATIONS")} style={menuActive === "CANCELLATIONS" ? { color: "#EA1D2C" } : { color: "#000" }}>Cancelamentos{cancellationRequests.length > 0 ? (<div className="config-menu-count">{cancellationRequests.length}</div>) : (null)}</div>
                </div>
                <div className="vertical-config-line"></div>
                {menuActive === "CHANGES" ? (
                    <div className="config-content">
                        <div className="header-config">
                            <div className="title">Solicitações de Alteração de Endereço</div>
                        </div>
                        {isLoadingChanges.current ? (
                            <div className="loading-content">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        ) : (
                            <div className="content">
                                {changeRequests.length && loadingChangeRequests ? (
                                    <table className="interruptions-list">
                                        <thead>
                                        <tr>
                                                <th>ID do Pedido</th>
                                                <th>Endereço Solicitado</th>
                                                <th className="accept-action">Aceitar</th>
                                                <th className="reject-action">Rejeitar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {changeRequests.map((changeRequest) => (
                                                <tr key={changeRequest.orderId}>
                                                    <td>{changeRequest.orderId}</td>
                                                    <td>{formatAddress(changeRequest.address)}</td>
                                                    <td className="accept-action"><FontAwesomeIcon icon={faCheck} onClick={() => acceptDeliveryAddressChangeRequest(changeRequest.orderId)} color="#50A773" style={{ cursor: "pointer", height: 16 }} /></td>
                                                    <td className="reject-action"><FontAwesomeIcon icon={faXmark} onClick={() => rejectDeliveryAddressChangeRequest(changeRequest.orderId)} color="#EA1D2C" style={{ cursor: "pointer", height: 16 }} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="not-interruptions">Parece que não há nenhuma solicitação de alteração de endereço de entrega por enquanto!</div>
                                )}
                            </div>

                        )}
                    </div>
                ) : (
                    <div className="config-content">
                        <div className="header-config">
                            <div className="title">Solicitações Cancelamento</div>
                        </div>
                        {isLoadingCancellations.current ? (
                            <div className="loading-content">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        ) : (
                            <div className="content">
                                {cancellationRequests.length && loadingCancellationRequests ? (
                                    <table className="interruptions-list">
                                        <thead>
                                            <tr>
                                                <th>Motivo</th>
                                                <th>Tipo de Cancelamento</th>
                                                <th>Tipo de Negociação</th>
                                                <th className="accept-action">Aceitar</th>
                                                <th className="reject-action">Rejeitar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cancellationRequests.map((cancellationRequest) => (
                                                <tr key={cancellationRequest.id}>
                                                    <td>{cancellationRequest.message}</td>
                                                    <td>{cancellationRequest.action}</td>
                                                    <td>{cancellationRequest.handshakeType}</td>
                                                    <td className="accept-action"><FontAwesomeIcon icon={faCheck} onClick={() => acceptHandShakeDispute(cancellationRequest.disputeId)} color="#50A773" style={{ cursor: "pointer", height: 16 }} /></td>
                                                    <td className="reject-action"><FontAwesomeIcon icon={faXmark} onClick={() => rejectHandShakeDispute(cancellationRequest.disputeId)} color="#EA1D2C" style={{ cursor: "pointer", height: 16 }} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="not-interruptions">Parece que não há nenhuma solicitação de cancelamento por enquanto!</div>
                                )}
                            </div>

                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default HandShake;