export default class Order {
    constructor (
        requester,
        orderDetails,
        store
    ) {
        this.requester = requester;
        this.orderDetails = orderDetails;
        this.store = store;
    }
}