import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faClock, faEye, faEyeSlash, faWallet, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/UserContext';
import './style.scss';
import WalletService from '../../../services/WalletService';
import Conversor from '../../../helper/Conversor';

const Wallet = (props) => {
  const [hideBalance, setHideBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const { user } = useContext(UserContext);

  async function loadWallet() {
    if (loading) {
      return;
    }
    setLoading(true);
    props.setSpinner(true);
    setTimeout(async () => {
      const walletService = new WalletService();
      const response = await walletService.loadWallet(user._id);
      setWallet(response);
      props.setSpinner(false);
    }, 2000);
  }

  useEffect(() => {
    document.title = `Carteira | Motum`;
  });

  useEffect(() => {
    loadWallet();
    // eslint-disable-next-line
  }, [loading]);

  const transactionMap = {
    "CREDIT": "CRÉDITO",
    "DEBIT": "DÉBITO",
    "PENDING": "PENDENTE",
    "APROVED": "APROVADO",
    "CANCELLED": "CANCELADO"
  }

  const transactionIconMap = {
    "APROVED": <FontAwesomeIcon icon={faCheckSquare} color="#168821ff" style={{ marginRight: 10 }} />,
    "PENDING": <FontAwesomeIcon icon={faClock} color="#ffcd07ff" style={{ marginRight: 10 }} />,
    "CANCELLED": <FontAwesomeIcon icon={faWindowClose} color="#e52207ff" style={{ marginRight: 10 }} />,
  };

  return (
    <React.Fragment>
      <div className="content-container">
        <div className="main-balance-container">
          <div className="balance-info-container">
            <div className="balance-info-header">
              <span>Conta</span>
              <FontAwesomeIcon icon={faWallet} />
            </div>
            <div className="balance-info-amount-container">
              <div className="balance-info-amount">
                <div className="amount-title">Saldo</div>
                <div className="amount-value">{!hideBalance && wallet ? Conversor.converterStringReal(wallet.amount.value) : "***********"}</div>
              </div>
              <div className="hide-amount" onClick={() => setHideBalance(prevState => !prevState)}><FontAwesomeIcon icon={!hideBalance ? faEyeSlash : faEye} /></div>
            </div>
          </div>
          {/* 
          Descomentar quando houver botões de ação na carteira
          <div className="balance-actions-container">
            <div className="action-item">
              <div className="action-icon"><FontAwesomeIcon icon={faCircleDollarToSlot}/></div>
              <div className="action-text">Depositar</div>
            </div>
            <div className="action-item">
              <div className="action-icon"><FontAwesomeIcon icon={faMoneyBillTransfer}/></div>
              <div className="action-text">Sacar</div>
            </div>
            <div className="action-item">
              <div className="action-icon"><FontAwesomeIcon icon={faArrowTrendUp}/></div>
              <div className="action-text">Extrato</div>
            </div>
          </div> */}
        </div>
        <div className="transactions-container">
          <div className="transactions-title">Histórico de transações</div>
          <div className="transactions-table">
            <table>
              <thead>
                <tr>
                  <th className="transaction-date">Data da transação</th>
                  <th className="transaction-type">Tipo</th>
                  <th className="transaction-value">Valor</th>
                  <th className="transaction-description">Descrição</th>
                  <th className="transaction-status">Status</th>
                </tr>
              </thead>
              <tbody>
                {wallet && wallet.transactions
                  .sort((a, b) => new Date(b.movementAt) - new Date(a.movementAt))
                  .map((transaction, index) => (
                    <tr key={index}>
                      <td className="transaction-date">{Conversor.converterData(transaction.movementAt)}</td>
                      <td className={`transaction-type${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transactionMap[transaction.type]}</td>
                      <td className={`transaction-value${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transaction.type === "CREDIT" ? "+ " : "- "}{Conversor.converterStringReal(transaction.amount.value)}</td>
                      <td className="transaction-description">{transaction.description ? transaction.description : "Não informado"}</td>
                      <td className="transaction-status">{transactionIconMap[transaction.status]}{transactionMap[transaction.status]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Wallet;
