import React from "react";
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCheckSquare, faTruckLoading, faUserCheck } from '@fortawesome/free-solid-svg-icons';

const Notification = (props) => {

    
    const notificationData = props.notificationData;
    const order = notificationData.order;
    const deliveryMan = notificationData.deliveryman;
    const eventType = notificationData.eventType;

    const event_map = {
        "ACCEPT_ORDER": `Entrega #${order.orderDetails.deliveryCode} foi aceita por ${deliveryMan.name}`,
        "ARRIVED_ORDER": `O Entregador ${deliveryMan.name} chegou na loja para retirar a entrega #${order.orderDetails.deliveryCode}`,
        "WITHDRAWN_ORDER": `O Entregador ${deliveryMan.name} retirou a entrega #${order.orderDetails.deliveryCode}`,
        "DELIVERED_ORDER": `O Entregador ${deliveryMan.name} concluiu a entrega #${order.orderDetails.deliveryCode}`
    }

    const icon_map = {
        "ACCEPT_ORDER": <FontAwesomeIcon icon={faUserCheck} color='#168821ff' style={{ height: 15, marginRight: 10 }} />,
        "ARRIVED_ORDER": <FontAwesomeIcon icon={faBoxOpen} color='#FFA500' style={{ height: 15, marginRight: 10 }} />,
        "WITHDRAWN_ORDER": <FontAwesomeIcon icon={faTruckLoading} color='#155bcbff' style={{ height: 15, marginRight: 10 }} />,
        "DELIVERED_ORDER": <FontAwesomeIcon icon={faCheckSquare}  color='#168821ff' style={{ height: 15, marginRight: 10 }} />
    }

    return (
        <>
        <div className="notification-container">
            <p className="notification-title">{icon_map[eventType]}Aviso</p>
            <p className="notification-text">{event_map[eventType]}</p>
        </div>
        </>
    );
};

export default Notification;
