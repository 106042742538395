import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faChevronDown, faChevronUp, faGear, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useSocket } from "../../context/SocketContext";
import OrderService from "../../../services/OrderService";
import OrderCountdown from "./components/OrderCountdown";
import Swal from 'sweetalert2';
import OrderDetails from "./components/OrderDetails";
import './scss/list.scss';
import './scss/details.scss';
import './scss/modal.scss';
import './scss/main.scss';
import IfoodModal from "./components/IfoodModal";
import IfoodService from "../../../services/IfoodService";
import { UserContext } from "../../context/UserContext";
import ConfigMenu from "./components/ConfigMenu";
import HandShake from "./components/HandShake";

const Management = (props) => {
    const socket = useSocket();
    const { selectedStore } = useContext(UserContext);
    const [loadingIfoodOrders, setLoadingIfoodOrders] = useState(false);
    const isLoading = useRef(false);

    // Modal states
    const [configModalVisible, setConfigModalVisible] = useState(false);
    const [handShakeModalVisible, setHandShakeModalVisible] = useState(false);

    // Open Boxes Statess
    const [pendingDropListOpen, setPendingDropListOpen] = useState(true);
    const [confirmedDropListOpen, setConfirmedDropListOpen] = useState(true);
    const [dispatchedDropListOpen, setDispatchedDropListOpen] = useState(false);
    const [readyToPickupDropListOpen, setReadyToPickupDropListOpen] = useState(false);
    const [completedDropListOpen, setCompletedDropListOpen] = useState(false);
    const [cancelledDropListOpen, setCancelledDropListOpen] = useState(false);

    // Orders Storage
    const [ifoodPendingOrders, setIfoodPendingOrders] = useState([]);
    const [ifoodConfirmedOrders, setIfoodConfirmedOrders] = useState([]);
    const [ifoodDispatchedOrders, setIfoodDispatchedOrders] = useState([]);
    const [ifoodReadyToPickupOrders, setIfoodReadyToPickupOrders] = useState([]);
    const [ifoodCompletedOrders, setIfoodCompletedOrders] = useState([]);
    const [ifoodCancelledOrders, setIfoodCancelledOrders] = useState([]);

    // order details selected order
    const [selectedOrder, setSelectedOrder] = useState(null);

    const orderService = useMemo(() => new OrderService(), []);
    const ifoodService = useMemo(() => new IfoodService(), []);

    const handleHandShakeModal = () => {
        setHandShakeModalVisible((prevState) => !prevState);
    }

    const handleConfigModal = () => {
        setConfigModalVisible((prevState) => !prevState);
    }

    async function handleConfirmOrder(orderId, storeId) {
        try {
            props.setSpinner(true);
            const response = await orderService.confirmOrder(orderId, storeId);
            props.setSpinner(false);
            if (response.ok) {
                Swal.fire({
                    title: 'Pedido confirmado!',
                    text: 'Fique atento ao prazo de entrega.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false,
                    didClose: () => {
                        setLoadingIfoodOrders(false);
                    }
                });
            }
        } catch (error) {
            props.setSpinner(false);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false,
            });
        }
    }

    async function handleDispatchOrder(orderId, storeId) {
        try {
            props.setSpinner(true);
            const response = await orderService.dispatchOrder(orderId, storeId);
            props.setSpinner(false);
            if (response.ok) {
                Swal.fire({
                    title: 'Pedido despachado!',
                    text: 'O cliente já foi informado que o pedido está a caminho.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false,
                    didClose: () => {
                        setLoadingIfoodOrders(false);
                    }
                });
            }
        } catch (error) {
            props.setSpinner(false);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false
            });
        }
    }

    async function handleReadyToPickupOrder(orderId, storeId) {
        try {
            props.setSpinner(true);
            const response = await orderService.readyToPickupOrder(orderId, storeId);
            props.setSpinner(false);
            if (response.ok) {
                Swal.fire({
                    title: 'Pedido pronto para retirada!',
                    text: 'O cliente já foi informado que o pedido está pronto para retirada.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false,
                    didClose: () => {
                        setLoadingIfoodOrders(false);
                    }
                });
            }
        } catch (error) {
            props.setSpinner(false);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false
            });
        }
    }

    function createInterruptionForm({ description, start, end }) {
        const jsonBody = {
            description: description.value,
            start: new Date(start.value+'Z').toISOString(),
            end: new Date(end.value+'Z').toISOString()
        };
        return jsonBody;
    }


    const onSubmitInterruptionForm = async (event) => {
        event.preventDefault();
        props.setSpinner(true);
        try {
            const requestBody = await createInterruptionForm(event.target.elements);
            const response = await ifoodService.createMerchantInterruption(selectedStore._id, requestBody)
            props.setSpinner(false);
            event.target.reset();
            if (response.ok) {
                Swal.fire({
                    title: 'Interrupção criada com sucesso!',
                    text: 'Confira suas interrupções na aba Configurações -> Interrupções.<br> As alterações podem demorar alguns minutos.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false
                });
            }

        } catch (error) {
            props.setSpinner(false);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
            });
        }
    };

    const loadIfoodOrders = useCallback(async () => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        // Limpando todos os pedidos
        setIfoodPendingOrders([]);
        setIfoodConfirmedOrders([]);
        setIfoodDispatchedOrders([]);
        setIfoodReadyToPickupOrders([]);
        setIfoodCompletedOrders([]);
        setIfoodCancelledOrders([]);

        setLoadingIfoodOrders(true);
        try {
            const ifoodOrders = await orderService.getIfoodOrders();
            if (ifoodOrders.length) {
                ifoodOrders.forEach(order => {
                    switch (order.orderDetails.ifoodDetails.status) {
                        case "PLACED":
                            setIfoodPendingOrders(prevArray => [...prevArray, order]);
                            break;
                        case "CONFIRMED":
                            setIfoodConfirmedOrders(prevArray => [...prevArray, order]);
                            break;
                        case "DISPATCHED":
                            setIfoodDispatchedOrders(prevArray => [...prevArray, order]);
                            break;
                        case "READY_TO_PICKUP":
                            setIfoodReadyToPickupOrders(prevArray => [...prevArray, order]);
                            break;
                        case "CONCLUDED":
                            setIfoodCompletedOrders(prevArray => [...prevArray, order]);
                            break;
                        case "CANCELLED":
                            setIfoodCancelledOrders(prevArray => [...prevArray, order]);
                            break;
                        default:
                            break;
                    }
                });
            }
        } finally {
            isLoading.current = false;
        }
    }, [orderService]);

    useEffect(() => {
        socket.on('LOADING', () => {
            setLoadingIfoodOrders(false);
        });

        socket.on('IFOOD_PENDING_ORDER', (data) => {
            setSelectedOrder(data);
        });

        socket.on('IFOOD_CONFIRMED_ORDER', (data) => {
            setSelectedOrder(data);
        });

        socket.on('IFOOD_DISPATCHED_ORDER', (data) => {
            setSelectedOrder(data);
        });

        socket.on('IFOOD_READY_TO_PICKUP_ORDER', (data) => {
            setSelectedOrder(data);
        });

        socket.on('IFOOD_COMPLETED_ORDER', (data) => {
            setSelectedOrder(data);
        });

        socket.on('IFOOD_CANCELLED_ORDER', (data) => {
            setSelectedOrder(data);
            Swal.fire({
                title: `Pedido #${data.orderDetails.ifoodDetails.displayId} cancelado! \n`,
                html: `Código: ${data.orderDetails.ifoodDetails.cancellationDetails.cancelCodeId}
                    <br>Motivo: ${data.orderDetails.ifoodDetails.cancellationDetails.description}`,
                icon: 'warning',
                confirmButtonText: 'Ok, entendi!',
                confirmButtonColor: '#EA1D2C',
                didClose: () => {
                    setLoadingIfoodOrders(false);
                }
            });
        });

        return () => {
            socket.off('LOADING');
            socket.off('IFOOD_PENDING_ORDER');
            socket.off('IFOOD_CONFIRMED_ORDER');
            socket.off('IFOOD_DISPATCHED_ORDER');
            socket.off('IFOOD_READY_TO_PICKUP_ORDER');
            socket.off('IFOOD_COMPLETED_ORDER');
            socket.off('IFOOD_CANCELLED_ORDER');
        }
    }, [socket]);

    useEffect(() => {
        if (!isLoading.current && !loadingIfoodOrders) {
            loadIfoodOrders();
        }
    }, [loadingIfoodOrders, loadIfoodOrders]);

    const handlePendingBoxes = () => {
        setPendingDropListOpen((prevState) => !prevState);
    }

    const handleConfirmedBoxes = () => {
        setConfirmedDropListOpen((prevState) => !prevState);
    }

    const handleDispatchedBoxes = () => {
        setDispatchedDropListOpen((prevState) => !prevState);
    }

    const handleReadyToPickupBoxes = () => {
        setReadyToPickupDropListOpen((prevState) => !prevState);
    }

    const handleCompletedBoxes = () => {
        setCompletedDropListOpen((prevState) => !prevState);
    }

    const handleCancelledBoxes = () => {
        setCancelledDropListOpen((prevState) => !prevState);
    }

    const handleSelectedOrder = (order) => {
        setSelectedOrder(order);
    }

    const convertDateToHoursAndMinutes = (deliveryDateTime) => {
        const date = new Date(deliveryDateTime);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    return (
        <React.Fragment>
                <div className="content-container ifood-orders-container">

                    <div className="action-buttons-container">
                        <FontAwesomeIcon icon={faHandshake} className="config-icon" onClick={handleHandShakeModal} style={{ marginRight: 10 }} />
                        <FontAwesomeIcon icon={faGear} className="config-icon" onClick={handleConfigModal} />
                    </div>

                    <div className="ifood-orders-list-container">
                        <div className={selectedStore.isOpenStore ? `is-open-store` : `is-closed-store`}>
                            {selectedStore.isOpenStore ? `Loja Aberta` : `Loja Fechada`}
                        </div>
                        <h1 className="orders-list-title">Lista de Pedidos</h1>

                        {ifoodPendingOrders.length ? (
                            <div className="pending-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Aceitar Pedidos</span>
                                        <span className="header-count">{ifoodPendingOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={pendingDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handlePendingBoxes} /></div>
                                </div>
                                {pendingDropListOpen && ifoodPendingOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="pending-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <OrderCountdown createdAt={order.orderDetails.ifoodDetails.createdAt} />
                                        </div>
                                        {order.orderDetails.ifoodDetails.orderType === "TAKEOUT" ? (
                                            <div className="takeout-label">
                                                <FontAwesomeIcon icon={faBagShopping} height={10} style={{ marginRight: 5 }} />
                                                Retirada
                                            </div>
                                        ) : (null)}
                                        <button type="button" className="action-button accept-button" onClick={() => { handleConfirmOrder(order._id, order.store.id) }}>Aceitar Pedido</button>
                                    </div>
                                ))}
                            </div>
                        ) : (null)}

                        {ifoodConfirmedOrders.length ? (
                            <div className="confirmed-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Em preparo</span>
                                        <span className="header-count">{ifoodConfirmedOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={confirmedDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handleConfirmedBoxes} /></div>
                                </div>
                                {confirmedDropListOpen && ifoodConfirmedOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="confirmed-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <strong className="order-delivery-forecast">
                                                {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (
                                                    `Entregue até ${convertDateToHoursAndMinutes(order.orderDetails.ifoodDetails.deliveryDateTime)}`
                                                ) : (`Retirada prevista às ${convertDateToHoursAndMinutes(order.orderDetails.ifoodDetails.takeout.takeoutDateTime)}`)}
                                            </strong>
                                            {order.orderDetails.ifoodDetails.orderType === "TAKEOUT" ? (
                                                <div className="takeout-label">
                                                    <FontAwesomeIcon icon={faBagShopping} height={10} style={{ marginRight: 5 }} />
                                                    Retirada
                                                </div>
                                            ) : (null)}
                                            {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (
                                                <div className="pickup-code">
                                                    <p className="pickup-code-title">Código de Confirmação de Coleta</p>
                                                    <p className="pickup-code-detail">{order.orderDetails.ifoodDetails.delivery.pickupCode}</p>
                                                </div>
                                            ) : (null)}
                                        </div>
                                        {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (
                                            <button type="button" className="action-button dispatch-button" onClick={() => { handleDispatchOrder(order._id, order.store.id) }}>Despachar Pedido</button>
                                        ) : (<button type="button" className="action-button dispatch-button" onClick={() => { handleReadyToPickupOrder(order._id, order.store.id) }}>Avisar Pedido Pronto</button>)}
                                    </div>
                                ))}
                            </div>
                        ) : (null)}

                        {ifoodDispatchedOrders.length ? (
                            <div className="dispatched-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Em entrega</span>
                                        <span className="header-count">{ifoodDispatchedOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={dispatchedDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handleDispatchedBoxes} /></div>
                                </div>
                                {dispatchedDropListOpen && ifoodDispatchedOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="confirmed-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <strong className="order-delivery-forecast">Despachado há 1min</strong>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (null)}

                        {ifoodReadyToPickupOrders.length ? (
                            <div className="dispatched-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Pronto</span>
                                        <span className="header-count">{ifoodReadyToPickupOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={readyToPickupDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handleReadyToPickupBoxes} /></div>
                                </div>
                                {readyToPickupDropListOpen && ifoodReadyToPickupOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="confirmed-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <strong className="order-delivery-forecast">Pronto</strong>
                                            {order.orderDetails.ifoodDetails.orderType === "TAKEOUT" ? (
                                                <div className="takeout-label">
                                                    <FontAwesomeIcon icon={faBagShopping} height={10} style={{ marginRight: 5 }} />
                                                    Retirada
                                                </div>
                                            ) : (null)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (null)}

                        {ifoodCompletedOrders.length ? (
                            <div className="completed-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Entregue</span>
                                        <span className="header-count">{ifoodCompletedOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={completedDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handleCompletedBoxes} /></div>
                                </div>
                                {completedDropListOpen && ifoodCompletedOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="confirmed-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <strong className="order-delivery-forecast">Concluído</strong>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (null)}

                        {ifoodCancelledOrders.length ? (
                            <div className="cancelled-orders-list main-list-box">
                                <div className="header-box">
                                    <div className="header-content">
                                        <span className="header-text">Cancelado</span>
                                        <span className="header-count">{ifoodCancelledOrders.length}</span>
                                    </div>
                                    <div className="header-icon"><FontAwesomeIcon icon={cancelledDropListOpen ? faChevronUp : faChevronDown} height={15} onClick={handleCancelledBoxes} /></div>
                                </div>
                                {cancelledDropListOpen && ifoodCancelledOrders.map(order => (
                                    <div className="box" onClick={() => handleSelectedOrder(order)} key={order._id}>
                                        <div className="confirmed-info">
                                            <strong className="order-code">#{order.orderDetails.ifoodDetails.displayId}</strong>
                                            <strong className="order-delivery-forecast">Cancelado</strong>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (null)}
                    </div>
                    <OrderDetails order={selectedOrder} setSpinner={props.setSpinner} />
                </div>
            {/* Modal de Configurações */}
            <IfoodModal show={configModalVisible} onClose={handleConfigModal}>
                <ConfigMenu
                    onSubmitInterruptionForm={onSubmitInterruptionForm}
                    ifoodService={ifoodService}
                    selectedStore={selectedStore}
                />
            </IfoodModal>

            {/* Modal de Negociação */}
            <IfoodModal show={handShakeModalVisible} onClose={handleHandShakeModal}>
                <HandShake
                    ifoodService={ifoodService}
                    selectedStore={selectedStore}
                />
            </IfoodModal>
        </React.Fragment>
    );
}

export default Management;