export default class Shipping {
    constructor({ origem, destino, distanciaMetros, duracaoMinutos, valorCorridaCentavos }) {
        this._origem = origem;
        this._destino = destino;
        this._distanciaMetros = distanciaMetros;
        this._duracaoMinutos = duracaoMinutos;
        this._valorCorridaCentavos = valorCorridaCentavos;
    }

    get origem() {
        return this._origem;
    }

    get destino() {
        return this._destino;
    }

    get distanciaMetros() {
        return this._distanciaMetros;
    }

    get distanciaToString() {
        if (this._distanciaMetros <= 1000) return `${this._distanciaMetros} metros`;
        return `${(this._distanciaMetros / 1000).toFixed(2)} km`;
    }

    get duracaoMinutos() {
        return this._duracaoMinutos;
    }

    get valorCorridaCentavos() {
        return this._valorCorridaCentavos;
    }
}
