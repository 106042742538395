import React, { useContext, useState } from "react";
import motumIcon from "./../../../assets/img/motum-logo.png";
import HomePage from "./components/HomePage";
import MyDelivery from "./components/MyDelivery";
import Services from "./components/Services";
import HelpCenter from "./components/HelpCenter";
import { Navigate } from "react-router";
import './style.scss';
import { UserContext } from "../../context/UserContext";
import { AppBar, Toolbar, IconButton, Box, Button, Menu, MenuList, MenuItem, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import BoltIcon from '@mui/icons-material/Bolt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export default function Home(props) {
    const [anchorNav, setAnchorNav] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Home");
    const [previousPage, setPreviousPage] = useState(null);
    const { hideSidebar, setHideSidebar, user, selectedStore } = useContext(UserContext);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget); // Ajustado para definir o ponto de ancoragem correto
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    if (redirect) {
        if (user && selectedStore) {
            setHideSidebar(false);
            return <Navigate to={"/request"} />;
        } else {
            setHideSidebar(true);
            return <Navigate to={"/login"} />;
        }
    }

    const handlePageChange = (page) => {
        if (page !== selectedPage) {
            setPreviousPage(selectedPage);
            setSelectedPage(page);
        }
    };

    if (!hideSidebar) {
        setHideSidebar(true);
    }

    return (
        <React.Fragment>
            <body>
            <AppBar position="fixed" className="navbar">
                <Toolbar className="toolbar">
                    <IconButton size="large" edge="start" color="inherit" aria-label="logo" onClick={() => handlePageChange("Home")}>
                        <img src={motumIcon} alt="Motum" className="motum-logo" />
                    </IconButton>
                    {selectedPage === "Home" ? (<div className="selected-link">.</div>) : null}

                    <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Button className="nav-item" onClick={() => handlePageChange("Services")}>Serviços</Button>
                                {selectedPage === "Services" ? (<div className="selected-link">.</div>) : null}
                        <Button className="nav-item" onClick={() => handlePageChange("MyDelivery")}>Minha Entrega</Button>
                                {selectedPage === "MyDelivery" ? (<div className="selected-link">.</div>) : null}
                        <Button className="nav-item">Parceiros</Button>
                        <Button className="nav-item">Ferramentas</Button>
                        <Button className="nav-item">Blog</Button>
                        <Button className="nav-item">Central de Ajuda</Button>
                        <Button className="login-button" onClick={() => setRedirect(true)}>Login</Button>
                        <Button className="create-account-button">Criar Conta</Button>
                    </Box>

                    <IconButton
                        edge="end"
                        onClick={openMenu}
                        sx={{ display: { xs: 'flex', lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Menu
                        anchorEl={anchorNav}
                        open={Boolean(anchorNav)}
                        onClose={closeMenu}
                    >
                        <MenuList>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("Services")}>Serviços</Button>
                                {selectedPage === "Services" ? (<div className="selected-link">.</div>) : null}
                            </MenuItem>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("MyDelivery")}>Minha Entrega</Button>
                                {selectedPage === "MyDelivery" ? (<div className="selected-link">.</div>) : null}
                            </MenuItem>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("Parceiros")}>Parceiros</Button>
                            </MenuItem>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("Ferramentas")}>Ferramentas</Button>
                            </MenuItem>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("Blog")}>Blog</Button>
                            </MenuItem>
                            <MenuItem>
                                <Button className="nav-item" onClick={() => handlePageChange("Central de Ajuda")}>Central de Ajuda</Button>
                            </MenuItem>
                            <MenuItem>
                                <Button className="login-button" onClick={() => handlePageChange("Login")}>Login</Button>
                            </MenuItem>
                            <MenuItem>
                                <Button className="create-account-button" onClick={() => handlePageChange("Criar Conta")}>Criar Conta</Button>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Toolbar>
            </AppBar>
            <div className="motum-home-main">
                    <div className={`page-transition ${selectedPage === "Home" ? 'fade-in' : 'fade-out'} ${previousPage === "Home" ? 'slide-out' : ''}`}>
                        {selectedPage === "Home" ? (<HomePage setRedirect={setRedirect}/>) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "MyDelivery" ? 'fade-in' : 'fade-out'} ${previousPage === "MyDelivery" ? 'slide-out' : ''}`}>
                        {selectedPage === "MyDelivery" ? (<MyDelivery />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Services" ? 'fade-in' : 'fade-out'} ${previousPage === "Services" ? 'slide-out' : ''}`}>
                        {selectedPage === "Services" ? (<Services />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "HelpCenter" ? 'fade-in' : 'fade-out'} ${previousPage === "HelpCenter" ? 'slide-out' : ''}`}>
                        {selectedPage === "HelpCenter" ? (<HelpCenter />) : null}
                    </div>
                </div>
            </body>
        </React.Fragment>
    );
}
