const SimpleMaskMoney = require("simple-mask-money");
export default class MaskMoney {
   static setMask(id) {
        const args = {
            allowNegative: false,
            negativeSignAfter: false,
            prefix: "R$ ",
            fixed: true,
            fractionDigits: 2,
            decimalSeparator: ",",
            thousandsSeparator: ".",
            cursor: "end",
        };
        SimpleMaskMoney.setMask(id, args);
    }
   
}
